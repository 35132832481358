import * as Checkbox from '@radix-ui/react-checkbox';
import PropTypes from 'prop-types';

import { SelectedOptionsPropType } from '@components/basting-selector/prop-types';
import { ByoOptionCard } from '../byo-option-card';
import { ChoicePropType } from '../prop-types';
import { OptionCard } from '../option-card';
import { OptionGroup } from '../option-group';

export const CheckboxChoiceType = ({ choice, selectedOptions, onChange, index, isByo }) => {
  const items = choice.optionsForPicker;
  return (
    <OptionGroup index={index} isByo={isByo}>
      {items.map((item, optionIndex) => {
        const checked = Boolean(selectedOptions.find(value => value === item.value));
        const isFirst = optionIndex === 0;
        const isLast = optionIndex === items.length - 1;
        const isAfterSelected = !isFirst && selectedOptions.includes(items[optionIndex - 1].value);
        const isBeforeSelected = !isLast && selectedOptions.includes(items[optionIndex + 1].value);
        return (
          <Checkbox.Root
            key={item.value}
            checked={checked}
            value={item.value}
            disabled={item.disabled}
            onCheckedChange={() => {
              // If item is currently selected, remove the value, otherwise add the value
              const values = checked
                ? selectedOptions.filter(value => value !== item.value)
                : selectedOptions.concat(item.value);

              onChange(values);
            }}
            asChild
          >
            {isByo ? (
              <ByoOptionCard
                choiceName={choice.name}
                isBeforeSelected={isBeforeSelected}
                isAfterSelected={isAfterSelected}
                option={item}
                maxQuantityLimit={choice.maximumOptionQuantity}
                maxSelection={choice.maximumOptionLimit}
              />
            ) : (
              <OptionCard option={item} />
            )}
          </Checkbox.Root>
        );
      })}
    </OptionGroup>
  );
};

CheckboxChoiceType.propTypes = {
  choice: ChoicePropType.isRequired,
  selectedOptions: SelectedOptionsPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number,
  isByo: PropTypes.bool,
};

CheckboxChoiceType.defaultProps = {
  index: undefined,
  isByo: false,
};
