import { BASTING_TYPES } from '@nandosaus/constants';
import { includes, values } from 'lodash';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledSystemPropTypes from '@styled-system/prop-types';

import { Box } from '../grid';
import { useTheme } from '../../hooks/useTheme';

const BastingChilli = ({ bastings, width }) => {
  const { fonts } = useTheme();
  return (
    <Box width={width}>
      <StyledBastingChilli
        xmlns="http://www.w3.org/2000/svg"
        width="266.9"
        height="342.2"
        fill="none"
        viewBox="0 0 266.9 342.2"
      >
        <g id="basting-extra-hot">
          <motion.path
            animate={includes(bastings, BASTING_TYPES.EXTRA_HOT) ? 'active' : 'default'}
            variants={{
              default: {
                d:
                  'M116.7 111C116.7 111 140.917 114.99 256.017 115.99C256.017 45.6896 174.618 61.1804 217.018 14.9804C217.018 14.9804 218.9 12.1 206 9C206 9 192.5 7.2 183 38C178.3 46.6 164.5 47.4 153 52C134 59.6 129.8 63 125.5 69.2C118.3 79.6 118.4 87 118.4 87C118.4 87 116.7 96.5 116.7 111Z',
              },
              active: {
                d:
                  'M61.8 106.5C61.8 106.5 140.916 115.001 256.016 116.001C256.016 45.701 174.6 61.2 217 15C217 15 218.9 12.1113 206 9.01128C206 9.01128 192.448 7.241 182.948 38.041C178.248 46.641 164.8 47.7144 153 51.5144C147.9 53.2144 66.6 48.5 67.8 54.5C71 61.5 78.7 80.8 78.7 80.8C78.7 83.2 70.3 92.8 61.8 106.5Z',
              },
            }}
            fill="#46230f"
          />
          <text
            fill="#fff"
            style={{
              fontFamily: fonts.brand,
              fontSize: 22,
            }}
            transform="translate(157.626 75.572)"
          >
            <tspan x={0} y={0}>
              Extra
            </tspan>
            <tspan x={4.4} y={26.8} fontSize={27}>
              Hot
            </tspan>
          </text>
        </g>
        <g id="basting-hot">
          <motion.path
            animate={includes(bastings, BASTING_TYPES.HOT) ? 'active' : 'default'}
            variants={{
              default: {
                d:
                  'M256 116L148.2 113.9L116.2 116.5C116.2 122.7 116 133.2 116.4 139.7C116.9 147.3 116.4 152.8 116.4 170.5L163.5 172L252 172C253.4 165.7 254 160.1 254 160C258 137 256 116 256 116Z',
              },
              active: {
                d:
                  'M256 116L148.7 113.3L46.8 114.9C49.6 121.2 59.4 141.5 59.4 141.5C59.4 141.5 58.6 150.8 53 167.9L164 171.4L252 172C253.4 165.7 254 160.1 254 160C258 137 256 116 256 116Z',
              },
            }}
            fill="#ce0a24"
          />
          <text
            fill="#fff"
            style={{
              fontFamily: fonts.brand,
              fontSize: 50,
            }}
            transform="translate(140.844 159.092)"
          >
            Hot
          </text>
        </g>
        <g id="basting-mild">
          <motion.path
            animate={includes(bastings, BASTING_TYPES.MILD) ? 'active' : 'default'}
            variants={{
              active: {
                d:
                  'M252 172C243.577 211.112 229.858 233.89 229.858 233.89L141.96 230.71L52.4577 228.69L57.1577 202.69C57.1577 202.69 54.6747 183.945 52.9577 171.49L164.399 171.19L252 172Z',
              },
              default: {
                d:
                  'M252 172C243.5 211.1 228.9 234.7 228.97 234.74L141 231.5L115.5 229.5L120.2 203.5C120.2 203.5 117.7 184.7 116 172.3L163.5 172L252 172Z',
              },
            }}
            fill="#faa500"
          />
          <text
            fill="#fff"
            style={{
              fontFamily: fonts.brand,
              fontSize: 50,
            }}
            transform="translate(135.252 222.254)"
          >
            Mild
          </text>
        </g>
        <g id="basting-lemon-herb">
          <motion.path
            fill="#96c31e"
            animate={includes(bastings, BASTING_TYPES.LEMON_AND_HERB) ? 'active' : 'default'}
            variants={{
              default: {
                d:
                  'M213 260C217.6 254.2 227.1 238.5 230 233L135.5 231.3L115.6 233.5C117.4 241.8 118.3 252.1 115.6 261.3C114 266.6 110.7 271.3 103.8 280.1L122.8 282.6L132.8 283.4L186.866 288C186.766 288 196.2 281.1 213 260Z',
              },
              active: {
                d:
                  'M213.068 260C217.668 254.2 230.068 233 230.068 233L86.1682 230.709L50.7682 230.309C52.6682 238.009 56.0682 255.909 56.0682 255.909C56.0682 255.909 43.6682 273.509 37.5682 279.709L62.5682 281.009L132.568 283.809L187 288C186.9 288.1 196.268 281.1 213.068 260Z',
              },
            }}
          />
          <text
            fill="#fff"
            style={{
              fontFamily: fonts.brand,
            }}
            transform="translate(130.44 252.89)"
          >
            <tspan x={0} y={0} fontSize={20}>
              Lemon
            </tspan>
            <tspan x={1} y={21.2} fontSize={21}>
              & Herb
            </tspan>
          </text>
        </g>
        <g id="basting-bbq">
          <motion.path
            animate={includes(bastings, BASTING_TYPES.BBQ) ? 'active' : 'default'}
            variants={{
              default: {
                d:
                  'M187 288C170.9 286.7 132.465 283.582 132.465 283.582L102.865 286.382C98.7647 289.282 93.8647 297.282 91.3647 302.082C90.6647 303.482 86.7647 311.582 87.8647 317.882C88.3647 320.582 89.0647 322.282 92.5647 325.982C94.9647 328.582 100.065 329.182 105.165 329.182C112.565 329.182 121.865 326.582 124.965 324.882C124.965 324.882 145.846 318.626 167.48 302.943L187 288Z',
              },
              active: {
                d:
                  'M187 288C170.9 286.7 127.199 283.6 127.199 283.6L12.1777 283.6C20.9917 295.246 13.1657 285.599 24.1077 300.417C23.5437 303.895 23.2477 303.267 9.5427 319.205C10.0427 321.905 89.7987 322.3 93.2987 326C95.6987 328.6 100.065 329.182 105.165 329.182C112.565 329.182 121.865 326.582 124.965 324.882C124.965 324.882 146.579 318.626 168.213 302.943L187 288Z',
              },
            }}
            fill="#5a642d"
          />
          <text
            fill="#fff"
            style={{
              fontFamily: fonts.brand,
              fontSize: 22,
            }}
            transform="translate(101.842 312.098)"
          >
            BBQ
          </text>
        </g>
      </StyledBastingChilli>
    </Box>
  );
};

const StyledBastingChilli = styled(motion.svg)`
  width: 100%;
  height: auto;
`;

BastingChilli.defaultProps = {
  bastings: [],
  width: '130px',
};

BastingChilli.propTypes = {
  bastings: PropTypes.arrayOf(PropTypes.oneOf(values(BASTING_TYPES))),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array, PropTypes.object]),
  ...StyledSystemPropTypes.layout,
};

export { BastingChilli };
