import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../../grid';
import { Decal } from '../../decal';
import { DecalWrapper } from '../../decal-wrapper';
import { P } from '../../typography';

const PerksSash = ({ children }) => {
  const clipPath = `polygon(0 calc(0% + 3vw), 100% 0, 100% calc(100% - 3vw), 0% 100%);`;

  return (
    <StyledBox clipPath={clipPath} width="100%" backgroundColor="neonTeal100">
      <DecalWrapper
        leftDecal={
          <Box
            height="100%"
            width="100%"
            zIndex={1}
            top="3vw"
            transform={{ _: 'translate(-50%, 15%)', md: 'translate(50%, 15%)' }}
            left={{ md: '0', lg: '100%' }}
            position="relative"
          >
            <Decal name="carouselFlame" color="perksBlue" width="auto" height="100%" right={0} />
          </Box>
        }
        rightDecal={
          <Box
            height="100%"
            width="100%"
            zIndex={1}
            left={{ md: '0', lg: '100%' }}
            top="3vw"
            transform={{
              _: 'rotate(354deg) translate(50%, -30%)',
              md: 'rotate(354deg) translate(60%,-30%)',
              xl: 'rotate(354deg) translate(60%,-50%)',
            }}
            position="relative"
          >
            <Decal name="carouselPepper" zIndex={1} color="perksBlue" width="auto" height="100%" right={0} />
          </Box>
        }
        decalChildrenStyle={{ zIndex: undefined }}
        overflow="hidden"
      >
        <Flex flexDirection="column" alignItems="center" py="4rem">
          <Box width={{ _: '106px', md: '181px' }} mb="1rem">
            <img alt="Peri Perks logo" src="/static/images/brand/peri-perks-logo.svg" width="181" height="175" />
          </Box>
          <P variant={2} maxWidth="200px" textAlign="center">
            {children}
          </P>
        </Flex>
      </DecalWrapper>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  clip-path: ${({ clipPath }) => clipPath};
`;

PerksSash.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PerksSash };
