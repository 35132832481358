import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';

import { A, P } from '../../../typography';

const FilterControl = ({ hasDisabledOptions }) => {
  const { DietaryPreferencesStore } = useRootStore();

  if (!hasDisabledOptions) {
    return null;
  }

  return (
    <P variant={4} mb="0.5rem">
      Showing dietary preferences
      <A variant={4} ml="0.5rem" onClick={() => DietaryPreferencesStore.clearDietaryPreferences()}>
        Clear filter
      </A>
    </P>
  );
};

FilterControl.propTypes = {
  hasDisabledOptions: PropTypes.bool.isRequired,
};

const ComposedFilterControl = observer(FilterControl);

export { ComposedFilterControl as FilterControl };
