import { colors } from '@nandosaus/constants';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { OptionPropType } from '@components/basting-selector/prop-types';
import { Box, Flex } from '../../../grid';
import { Icon } from '../../../icons';
import { Image } from '../../../image';
import { Label, P } from '../../../typography';
import { Placeholder } from '../placeholder';
import { QuantityPicker } from '../../../quantity-picker';

const ByoChoiceOption = styled(Flex).attrs(() => ({
  as: 'button',
  flexDirection: 'row',
  overflow: 'hidden',
  textAlign: 'left',
  py: '0.75rem',
  px: '0.75rem',
  my: 0,
}))`
  transition: none;
`;

const ByoBorder = styled(Flex).attrs(() => ({
  backgroundColor: 'white',
  border: '1px solid transparent',
  position: 'relative',
}))`
  border-bottom-color: ${colors.grey100};

  ${({ checked, isAfterSelected, isBeforeSelected }) => {
    if (checked) {
      return css`
        border-color: ${colors.green600};
        border-top-color: ${isAfterSelected ? 'transparent' : colors.green600};
      `;
    }
    return css`
      &:first-child {
        border-top-color: ${colors.grey100};
        border-bottom-color: ${isBeforeSelected ? 'transparent' : colors.grey100};
      }
    `;
  }}
`;

const ByoOptionCard = React.forwardRef(
  (
    { choiceName, maxSelection, maxQuantityLimit, option, label, isAfterSelected, isBeforeSelected, ...radixProps },
    forwardedRef
  ) => {
    const checked = get(radixProps, 'data-state') === 'checked';
    const { ProductDetailsState } = useRootStore();
    const itemDisabled = option.disabled || (!maxSelection && !maxQuantityLimit);
    const quantity = ProductDetailsState.getOptionQuantityForChoice(choiceName, option.value);
    const handleQuantityChange = useCallback(
      newQuantity => {
        ProductDetailsState.updateOptionQuantityForChoice(choiceName, option.value, newQuantity);
        if (newQuantity === 0) {
          ProductDetailsState.removeOptionForChoice(choiceName, option.value);
        }
      },
      [ProductDetailsState, choiceName, option.value]
    );
    const optionOpacity = itemDisabled ? 0.5 : 1;
    return (
      <ByoBorder checked={checked} isAfterSelected={isAfterSelected} isBeforeSelected={isBeforeSelected}>
        <ByoChoiceOption ref={forwardedRef} {...radixProps} width="100%">
          <Flex maxWidth="60%">
            <Box opacity={optionOpacity} minWidth="50px" minHeight="50px">
              {option.image ? (
                <Image src={option.image} sizes="50px" loading="lazy" />
              ) : (
                <Flex size="50px" backgroundColor={colors.grey100} alignItems="center" justifyContent="center">
                  <Placeholder width="75%" height="75%" preserveAspectRatio="xMidYMid meet" />
                </Flex>
              )}
            </Box>
            <Flex flexDirection="column" ml="0.75rem" alignItems="flex-start">
              {itemDisabled && (
                <Box px="0.5rem" backgroundColor={colors.grey600}>
                  <P variant={5} color="white" fontWeight="bold" whiteSpace="nowrap">
                    CURRENTLY UNAVAILABLE
                  </P>
                </Box>
              )}
              <P my={itemDisabled ? 'auto' : 0} variant={4} fontWeight="bold" color="black" opacity={optionOpacity}>
                {label || option.label}
              </P>
              {option.text && !itemDisabled && (
                <Label my="auto" variant={1} color="grey400">
                  {option.text}
                </Label>
              )}
            </Flex>
          </Flex>
        </ByoChoiceOption>
        {!itemDisabled && maxQuantityLimit > 1 && quantity > 0 && (
          <QuantityPicker
            increment={() => handleQuantityChange(quantity + 1)}
            decrement={() => handleQuantityChange(quantity - 1)}
            quantity={quantity}
            min={0}
            max={maxQuantityLimit}
            buttonSize={{ xs: 33, lg: 44 }}
            position="absolute"
            right="0.75rem"
            top="50%"
            transform="translateY(-50%)"
            zIndex={1}
          />
        )}
        {checked && (
          <Icon position="absolute" name="tickRound" fill="green500" bottom="-12px" right="-12px" zIndex={1} />
        )}
      </ByoBorder>
    );
  }
);

ByoOptionCard.propTypes = {
  choiceName: PropTypes.string,
  option: OptionPropType.isRequired,
  label: PropTypes.string,
  isAfterSelected: PropTypes.bool,
  isBeforeSelected: PropTypes.bool,
  maxQuantityLimit: PropTypes.number,
  maxSelection: PropTypes.number,
};

ByoOptionCard.defaultProps = {
  choiceName: undefined,
  label: undefined,
  isAfterSelected: false,
  isBeforeSelected: false,
  maxQuantityLimit: 0,
  maxSelection: 0,
};

const ComposedByoOptionCard = observer(ByoOptionCard);

export { ComposedByoOptionCard as ByoOptionCard };
