import { Children, useEffect, useRef, useState } from 'react';
import { useScroll } from 'framer-motion';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../../../grid';
import { Icon } from '../../../icons';
import { NavigationButton } from './navigation-button';

const ScrollWrapper = styled.div`
  display: flex;
  position: relative;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: ${({ verticalDisplay }) => (verticalDisplay ? 'column' : 'row')};
  padding: 0rem 1.5rem;
  flex-wrap: nowrap;
  gap: 0.5rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const DIRECTION = {
  NEXT: 'next',
  PREV: 'prev',
};

export const OptionGroup = ({ children, isByo, verticalDisplay }) => {
  const arrayChildren = Children.toArray(children);
  const optionGroupRef = useRef(null);
  const [navigationControls, setNavigationControls] = useState({
    isPrevButtonEnabled: false,
    isNextButtonEnabled: false,
  });
  const { scrollXProgress, scrollX } = useScroll({
    container: optionGroupRef,
  });

  const handleScroll = scrollProgress => {
    setNavigationControls({
      IsPrevButtonEnabled: scrollProgress > 0 && scrollX.get() > 0,
      IsNextButtonEnabled: scrollProgress < 1,
    });
  };

  const handleButtonClick = direction => {
    if (!optionGroupRef.current) {
      return;
    }

    optionGroupRef.current.scrollBy({ left: direction === DIRECTION.PREV ? -250 : 250, behavior: 'smooth' });
  };

  useEffect(() => {
    // Update navigation button state on mount
    handleScroll(scrollXProgress.get());

    // Update navigation button state on scroll
    return scrollXProgress.onChange(latest => {
      handleScroll(latest);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isByo) {
    return (
      <Flex flexDirection="column" px={{ xs: 2, md: 1 }}>
        {children}
      </Flex>
    );
  }

  return (
    <Box position="relative" pt="0.25rem">
      <ScrollWrapper ref={optionGroupRef} verticalDisplay={verticalDisplay}>
        {Children.map(arrayChildren, child => (
          <Box>{child}</Box>
        ))}
      </ScrollWrapper>
      {navigationControls.IsPrevButtonEnabled ? (
        <NavigationButton
          position="absolute"
          left="1.5rem"
          bottom="50%"
          onClick={() => handleButtonClick(DIRECTION.PREV)}
          title="Scroll backwards"
        >
          <Icon name="chevronLeft" width="1rem" fill="currentColor" />
        </NavigationButton>
      ) : null}
      {navigationControls.IsNextButtonEnabled ? (
        <NavigationButton
          position="absolute"
          right="1.5rem"
          bottom="50%"
          onClick={() => handleButtonClick(DIRECTION.NEXT)}
          title="Scroll forwards"
        >
          <Icon name="chevronRight" width="1rem" fill="currentColor" />
        </NavigationButton>
      ) : null}
    </Box>
  );
};

OptionGroup.propTypes = {
  children: PropTypes.node.isRequired,
  isByo: PropTypes.bool,
  verticalDisplay: PropTypes.bool,
};

OptionGroup.defaultProps = {
  isByo: false,
  verticalDisplay: false,
};
