import PropTypes from 'prop-types';

export const OptionPropType = PropTypes.shape({
  text: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  image: PropTypes.string,
});

export const SelectedOptionsPropType = PropTypes.arrayOf(PropTypes.string);
