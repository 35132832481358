import PropTypes from 'prop-types';
import { OptionPropType } from '@components/basting-selector/prop-types';

export const ChoicePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  maximumOptionLimit: PropTypes.number.isRequired,
  required: PropTypes.bool,
  optionsForPicker: PropTypes.arrayOf(OptionPropType),
  optionById: PropTypes.func.isRequired,
  hasDisabledOptions: PropTypes.bool,
});
