import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { useRootStore } from '@nandosaus/state-management';

import { Button } from '../../button';
import { IOSButtonWrapper } from '../../ios-button-wrapper';

const ConfirmButton = observer(({ analytics, onClick, variant, isFullWidth }) => {
  const { ProductDetailsState } = useRootStore();
  const isDisabled = ProductDetailsState.loading || !ProductDetailsState.isValid;

  const buttonProps = {
    analytics,
    onClick,
    variant: isDisabled ? 'inactive' : variant,
    isFullWidth,
  };

  const buttonText = (() => {
    if (ProductDetailsState.isEditingCartItem) {
      return 'Update order';
    }

    if (ProductDetailsState.orderItem.isMealProduct) {
      return 'Add meal to order';
    }

    if (ProductDetailsState.displayProduct.hasMealUpgrade) {
      return ProductDetailsState.quantity > 1 ? 'Add items only' : 'Add item only';
    }

    return ProductDetailsState.quantity > 1 ? 'Add items' : 'Add item';
  })();

  return (
    <IOSButtonWrapper>
      <Button
        {...buttonProps}
        as={motion.button}
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition="0.2s ease-in-out 0.3s"
      >
        {buttonText}
      </Button>
    </IOSButtonWrapper>
  );
});

ConfirmButton.propTypes = {
  analytics: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  isFullWidth: PropTypes.bool,
};

ConfirmButton.defaultProp = {
  isFullWidth: undefined,
};

export { ConfirmButton };
