import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import React from 'react';

import { Box, Flex } from '../grid';
import { Label, P } from '../typography';
import { GeneralAllergenPDFLink } from '../general-allergen-pdf-link';

const AllergyInformation = () => {
  const { ProductDetailsState } = useRootStore();

  const {
    orderItem: {
      dietaryDisclaimer,
      formattedContainsString,
      formattedDoesNotContainString,
      formattedNotSuitableFor,
      formattedSuitableFor,
      formattedTracesString,
    },
    isValid,
  } = ProductDetailsState;

  return (
    <>
      <P fontWeight="bold">Allergen Information</P>
      {!isValid && (
        <P variant={2}>Please make selections in order for nutritional and allergen info to be displayed.</P>
      )}
      {isValid && (
        <>
          <P variant={2}>
            Allergens for this item (if any) are listed below. Allergen info does not reflect any customisations made to
            menu items.
          </P>
          {!!formattedSuitableFor && (
            <Flex flexDirection="row" mt={1}>
              <Box width="40%">
                <Label color="grey300">Suitable for</Label>
              </Box>
              <Box width="60%">
                <P variant={2}>{formattedSuitableFor}</P>
              </Box>
            </Flex>
          )}
          {!!formattedNotSuitableFor && (
            <Flex flexDirection="row" mt={1}>
              <Box width="40%">
                <Label color="grey300">Not Suitable for</Label>
              </Box>
              <Box width="60%">
                <P variant={2}>{formattedNotSuitableFor}</P>
              </Box>
            </Flex>
          )}
          {!!formattedContainsString && (
            <Flex flexDirection="row" mt={1}>
              <Box width="40%">
                <Label color="grey300">Contains</Label>
              </Box>
              <Box width="60%">
                <P variant={2}>{formattedContainsString}</P>
              </Box>
            </Flex>
          )}
          {!!formattedDoesNotContainString && (
            <Flex flexDirection="row" mt={1}>
              <Box width="40%">
                <Label color="grey300">Does Not Contain</Label>
              </Box>
              <Box width="60%">
                <P variant={2}>{formattedDoesNotContainString}</P>
              </Box>
            </Flex>
          )}
          {!!formattedTracesString && (
            <Flex flexDirection="row" mt={1}>
              <Box width="40%">
                <Label color="grey300">May Contain</Label>
              </Box>
              <Box width="60%">
                <P variant={2}>{formattedTracesString}</P>
              </Box>
            </Flex>
          )}
          {!!dietaryDisclaimer && (
            <Flex flexDirection="row" mt={1}>
              <Box width="100%">
                <P variant={2} color="grey300">
                  {dietaryDisclaimer}
                </P>
              </Box>
            </Flex>
          )}
        </>
      )}
      <GeneralAllergenPDFLink />
    </>
  );
};

const ObservedAllergyInformation = observer(AllergyInformation);
export { ObservedAllergyInformation as AllergyInformation };
