import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { OptionPropType } from '@components/basting-selector/prop-types';
import { Badge } from '../../../badge';
import { Box, Flex } from '../../../grid';
import { OptionImage } from './option-image';
import { P } from '../../../typography';
import { CheckIndicator } from '../../../check-indicator';
import { RoundRadio } from '../choice-types/mixed-basting';

const Button = styled(Box).attrs(() => ({
  as: 'button',
  display: 'flex',
  flexDirection: 'column',
  width: { _: '108px', lg: '160px' },
  overflow: 'hidden',
  textAlign: 'left',
  height: '100%',
}))``;

const OptionCard = React.forwardRef(({ option, label, ...radixProps }, forwardedRef) => {
  const state = radixProps['data-state'];
  const checked = state === 'checked';

  return (
    <Button ref={forwardedRef} {...radixProps}>
      <Box as="span" display="block" width="100%" position="relative">
        <OptionImage option={option} selected={checked} height={200} width={200} />
        <CheckIndicator checked={checked} top="0.5rem" backgroundColor="black" />
        {option.disabled ? (
          <Badge as="span" type="secondary" position="absolute" top="0.5rem" left="0.5rem">
            Unavailable
          </Badge>
        ) : (
          option.isEligibleForDeliveryDiscount && (
            <Badge
              position="absolute"
              type="red"
              width="100%"
              bottom="0.5rem"
              left="0.5rem"
              fontSize={{ xs: '7px', lg: '11px' }}
            >
              Get free delivery
            </Badge>
          )
        )}
      </Box>
      <Box
        as="span"
        display="flex"
        flexDirection="column"
        px="0.5rem"
        py={{ _: '0.5rem', lg: '0.75rem' }}
        flexGrow={1}
        justifyContent="space-between"
        width="100%"
      >
        <P as="span" variant={3} fontWeight="bold" color={option.disabled ? 'grey300' : 'black'}>
          {label || option.label}
        </P>
        {option.text && (
          <P as="span" variant={4} color={option.disabled ? 'grey300' : 'black'}>
            {option.text}
          </P>
        )}
      </Box>
    </Button>
  );
});

OptionCard.propTypes = {
  option: OptionPropType.isRequired,
  label: PropTypes.string,
};

OptionCard.defaultProps = {
  label: undefined,
};

const ComposedOptionCard = observer(OptionCard);

const OptionLabelButton = styled(Box).attrs(() => ({
  as: 'button',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  boxSizing: 'border-box',
  borderRadius: { _: '4px', lg: '8px' },
  overflow: 'hidden',
  textAlign: 'left',
  height: '100%',
  alignItems: 'center',
}))``;

const OptionLabel = React.forwardRef(({ option, showOptionImage, label, ...radixProps }, forwardedRef) => {
  const state = radixProps['data-state'];
  const checked = state === 'checked';

  return (
    <OptionLabelButton ref={forwardedRef} {...radixProps}>
      <Flex width="100%" alignItems="center" gap="1rem">
        {showOptionImage && <OptionImage selected={checked} option={option} height={80} width={80} />}
        <Box flex={1}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <P as="span" fontWeight="bold" color={option.disabled ? 'grey300' : 'black'}>
              {label || option.label}
            </P>
            <RoundRadio checked={checked} />
          </Box>
          <Box display="flex" flexDirection="row" height="1rem" width="100%" gap="0.5rem">
            {option.text && (
              <P as="span" variant={4} color={option.disabled ? 'grey300' : 'black'}>
                {option.disabled ? 'Unavailable' : option.text}
              </P>
            )}
            {option.isEligibleForDeliveryDiscount && (
              <P as="span" variant={4} color={option.disabled ? 'grey300' : 'black'}>
                Get Free Delivery
              </P>
            )}
          </Box>
        </Box>
      </Flex>
    </OptionLabelButton>
  );
});

OptionLabel.propTypes = {
  option: OptionPropType.isRequired,
  label: PropTypes.string,
  showOptionImage: PropTypes.bool,
};

OptionLabel.defaultProps = {
  label: undefined,
  showOptionImage: true,
};

const ComposedOptionLabel = observer(OptionLabel);

export { ComposedOptionCard as OptionCard, ComposedOptionLabel as OptionLabel };
