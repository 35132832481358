import PropTypes from 'prop-types';

import { getBastingKey } from '@utils/get-basting-key/get-basting-key';
import { SelectedOptionsPropType } from '@components/basting-selector/prop-types';
import { BastingSelector } from '@components/basting-selector';
import { Flex } from '@components/grid';
import { ChoicePropType } from '../prop-types';
import { MixedBastingChoiceType } from './mixed-basting';
import { useFeature } from '../../../../hooks/use-feature';

const BastingChoiceType = ({ choice, selectedOptions, onChange }) => {
  const bastingOptions = choice.optionsForPicker;

  const selectedBastings = bastingOptions.filter(({ value }) => selectedOptions.includes(value));
  const selectedBastingKeys = selectedBastings.map(basting => getBastingKey(basting)).filter(Boolean);

  const isMixedBastingEnabled = useFeature('mixed-basting');
  if (isMixedBastingEnabled && choice?.maximumOptionLimit > 1) {
    return <MixedBastingChoiceType {...{ choice, selectedOptions, selectedBastingKeys, onChange }} />;
  }

  return (
    <Flex px="1.5rem">
      <BastingSelector selectedOptions={selectedOptions} bastingOptions={bastingOptions} onChange={onChange} />
    </Flex>
  );
};

BastingChoiceType.propTypes = {
  choice: ChoicePropType.isRequired,
  selectedOptions: SelectedOptionsPropType.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { BastingChoiceType };
