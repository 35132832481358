import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '../grid';

const MotionBox = motion(Box);

export const CheckIndicator = ({ checked, backgroundColor, color, bottom, top, right, left, zIndex, position }) => {
  return (
    <AnimatePresence initial={false}>
      {checked ? (
        <MotionBox
          as="span"
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor={backgroundColor}
          borderRadius="50%"
          width={{ _: '20px', lg: '26px' }}
          height={{ _: '20px', lg: '26px' }}
          position={position}
          bottom={bottom}
          top={top}
          right={right}
          left={left}
          initial={{
            opacity: 0,
            scale: 0,
          }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            scale: 0,
            transition: {
              duration: 0.1,
            },
          }}
          zIndex={zIndex}
          transition={{
            default: { ease: 'linear' },
          }}
        >
          <Box as="span" display="flex" width={{ _: '14px', lg: '18px' }}>
            <motion.svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              strokeWidth={3}
              stroke={color}
              fill="none"
              width="24"
              height="24"
            >
              <motion.path
                d="M5 13l4 4L19 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                data-testid="check-icon"
                initial={{
                  pathLength: 0,
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  pathLength: 1,
                  transition: {
                    delay: 0.15,
                    type: 'tween',
                    duration: 0.2,
                    ease: 'easeOut',
                  },
                }}
                exit={{ opacity: 0, transition: { duration: 0.1 } }}
              />
            </motion.svg>
          </Box>
        </MotionBox>
      ) : null}
    </AnimatePresence>
  );
};

CheckIndicator.propTypes = {
  checked: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  bottom: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
  zIndex: PropTypes.number,
  position: PropTypes.string,
};

CheckIndicator.defaultProps = {
  backgroundColor: 'success600',
  color: 'white',
  bottom: '-0.5rem',
  top: undefined,
  right: '0.5rem',
  left: undefined,
  zIndex: undefined,
  position: 'absolute',
};
