import { MARKET } from '@nandosaus/constants';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';

import { Box, Flex } from '../../../grid';
import { ByoProductDetailImage } from './product-detail-image';
import { H3, P } from '../../../typography';
import { Tags } from '../../../tags';
import { useFeature } from '../../../../hooks/use-feature';
import { useMarket } from '../../../../hooks/use-market';

const ByoProductDetailHeader = observer(({ product }) => {
  const { ProductDetailsState } = useRootStore();
  const market = useMarket();

  const isAustralianMarket = market === MARKET.AUSTRALIA;

  const sizePickerEnabled = useFeature('product-size-picker');
  const showPrice = !sizePickerEnabled || (product.hasOtherSizes !== undefined && !product.hasOtherSizes);

  const { buildYourOwnChoiceStep, isLastStep } = ProductDetailsState;

  return (
    <>
      <ByoProductDetailImage
        isLastStep={isLastStep}
        buildYourOwnChoiceStep={buildYourOwnChoiceStep}
        byoProductType={product.byoType}
      />
      {!isLastStep && (
        <Box p="1rem 0 0 1rem">
          <H3 mb="0.5rem">{product.name}</H3>
          <P my="0.5rem" color="grey500">
            From
          </P>
          <Flex mb="1rem" gap="1rem">
            {showPrice && product.prices.formattedPrice && <P fontWeight="bold">{product.prices.formattedPrice}</P>}
            {isAustralianMarket && product.formattedKilojoules && (
              <P textAlign="center" color="grey500">
                {product.formattedKilojoules}
              </P>
            )}
            {product.formattedDietaryPreferencesList && product.formattedDietaryPreferencesList && (
              <Box>
                <Tags labels={product.formattedDietaryPreferencesList} />
              </Box>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
});

export { ByoProductDetailHeader };
