import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../../constants';
import { Flex } from '../../grid';
import { H2 } from '../../typography';
import { useMarket } from '../../../hooks/use-market';

// Need to position Image as the shadow overlaps the text
const MealImage = styled.img`
  position: relative;
  height: 96px;
  margin: 0 -3rem;

  @media (min-width: ${breakpoints.lg}) {
    height: 192px;
  }
`;

const Header = ({ mealSelected, ...rest }) => {
  const market = useMarket();
  const imageSrc = `/static/images/product/meal_${market}.png`;

  return (
    <Flex {...rest}>
      <H2
        textAlign="center"
        lineHeight={1}
        mb={{ _: '1rem', lg: '2rem', xl: '1.5rem' }}
        mr={{ _: '-0.5rem', lg: '-3rem', xxl: '-1rem' }}
      >
        {mealSelected ? (
          <>
            Added to
            <br /> meal
          </>
        ) : (
          <>
            Make it a<br /> meal
          </>
        )}
      </H2>
      <MealImage src={imageSrc} />
    </Flex>
  );
};

Header.propTypes = {
  mealSelected: PropTypes.bool.isRequired,
};

export { Header };
