import styled from 'styled-components';

import { Box } from '../../../grid';

export const NavigationButton = styled(Box).attrs(() => ({
  as: 'button',
  display: { _: 'none', md: 'flex' },
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: '1.5rem',
  height: '1.5rem',
  'aria-hidden': true,
}))`
  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color 300ms ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  &:active {
  }
`;
