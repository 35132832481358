import { ANALYTICS_EVENTS, colors } from '@nandosaus/constants';
import { get, includes, isEmpty, size } from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';

import { ModalFloatingHeader } from '@components/modal/modal-header';
import { MealChoices } from '@components/meal-choices';
import { A, H3, P } from '../typography';
import { AllergyInformation } from '../allergy-information';
import { analytics } from '../../analytics';
import { Box, Flex } from '../grid';
import { Button } from '../button';
import { ByoProductContent } from './byo/product-content';
import { ChoicePicker } from './choice-picker';
import { ConfirmButton } from './confirm-button';
import { Modal } from '../modal';
import { NutritionalInformation } from '../nutritional-information';
import { ProductDetailHeader } from './product-detail-header';
import { ProductSizePicker } from './product-size-picker';
import { QuantityPicker } from '../quantity-picker';
import { RemoveIngredientsScreen } from './remove-ingredients-screen';
import { useFeature } from '../../hooks/use-feature';

const MODAL_SCREENS = {
  DEFAULT: 'default',
  INGREDIENTS: 'ingredients',
  MEAL: 'meal',
  REMOVE_INGREDIENTS: 'remove',
  ALLERGEN_AND_NUTRITIONAL: 'allergen-and-nutrional',
};

const ProductDetailModal = observer(
  ({ modalOpen, handleModalClose, handleModalSubmit: handleModalSubmitProp, showMealCta }) => {
    const handleModalSubmit = handleModalSubmitProp || handleModalClose;
    const [highlightMissingRequiredChoices, setHighlightMissingRequiredChoices] = useState(false);
    const [showRemoveIngredientMenu, setShowRemoveIngredientMenu] = useState(false);
    const [showAllergenAndNutritionalModal, setShowAllergenAndNutritionalModal] = useState(false);
    const { ProductDetailsState, CartStore, GroupOrderStore } = useRootStore();
    const sizePickerEnabled = useFeature('product-size-picker');
    const {
      buildYourOwnChoiceStep,
      displayProduct,
      isLastStep,
      orderItem,
      product,
      quantity,
      nextStepReady,
    } = ProductDetailsState;

    const showPerksSash = useMemo(() => get(orderItem, 'product.points') > 0 && !GroupOrderStore?.isGroupInvitee, [
      GroupOrderStore?.isGroupInvitee,
      orderItem,
    ]);

    const choiceNameRefs = useRef({});
    const modalScrollTop = useCallback(node => {
      if (node !== null) {
        node.scrollIntoView(true);
      }
    }, []);

    const modalScroll = useCallback(
      node => {
        if (node !== null) {
          node.scrollIntoView(ProductDetailsState.id ? { block: 'end' } : true);
        }
      },
      [ProductDetailsState.id]
    );

    const scrollToFirstMissingRequiredChoiceNode = useCallback(() => {
      if (isEmpty(ProductDetailsState.missingRequiredChoices)) {
        return;
      }

      const firstMissingRequiredChoiceNode = choiceNameRefs.current[ProductDetailsState.missingRequiredChoices[0]];
      if (firstMissingRequiredChoiceNode) {
        firstMissingRequiredChoiceNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, [ProductDetailsState.missingRequiredChoices]);

    const isMixedBastingEnabled = useFeature('mixed-basting');
    useEffect(() => {
      if (!isMixedBastingEnabled) {
        // on selection of a choice, scroll user to next required choice
        scrollToFirstMissingRequiredChoiceNode();
      }
    }, [ProductDetailsState.missingRequiredChoices, scrollToFirstMissingRequiredChoiceNode, isMixedBastingEnabled]);

    const isByoProduct = get(product, 'isByoProduct');
    const byoChoices = isByoProduct ? get(product, 'byoChoices', []) : [];

    const handleQuantityChange = useCallback(newQuantity => ProductDetailsState.updateQuantity(newQuantity), [
      ProductDetailsState,
    ]);

    // Reset ProductDetailsState on unmount
    useEffect(() => {
      return () => {
        ProductDetailsState.reset();
      };
    }, [ProductDetailsState]);

    if (!product) {
      return null;
    }

    const hasSelectedMealChoiceOptions = size(ProductDetailsState.chosenMealChoiceOptions) > 0;
    const modalButtonTitle = hasSelectedMealChoiceOptions ? `${displayProduct.name} Meal` : displayProduct.name;

    const confirmButton = (
      <ConfirmButton
        onClick={() => {
          if (ProductDetailsState.isEditingCartItem) {
            const { plu, name, productCategory } = orderItem.product;
            const { isReorderItem } = orderItem;

            analytics.track(ANALYTICS_EVENTS.ORDER_UPDATED, {
              cartId: CartStore.cartId,
              coupon: CartStore.offer ? CartStore.offer.description : undefined,
              value: orderItem.totals.dollarValue,
              product_id: plu,
              name,
              quantity: orderItem.quantity,
              category: productCategory?.name,
              isRecommendedItem: orderItem.isRecommendedItem,
            });

            if (isReorderItem) {
              analytics.track(ANALYTICS_EVENTS.REORDER_ITEM_EDITED, {
                cartId: CartStore.cartId,
                product_id: plu,
                name,
                quantity: orderItem.quantity,
                category: productCategory?.name,
                isRecommendedItem: orderItem.isRecommendedItem,
              });
            }
          }

          if (ProductDetailsState.isValid) {
            ProductDetailsState.saveToCart();
            handleModalSubmit();
          } else {
            setHighlightMissingRequiredChoices(true);
            scrollToFirstMissingRequiredChoiceNode();
          }
        }}
        variant="action"
        isFullWidth
      />
    );

    const handleContinue = () => {
      if (!isLastStep) {
        ProductDetailsState.nextStep();
      }
    };

    const handleBack = () => {
      if (!buildYourOwnChoiceStep) {
        handleModalClose();
        ProductDetailsState.resetStep();
      } else {
        ProductDetailsState.previousStep();
      }
    };

    const modalFooter = (
      <>
        {isByoProduct && !isLastStep ? (
          <Flex justifyContent="flex-start" width="100%" gap="12px">
            <Button variant="secondary" backgroundColor="grey50" isFullWidth onClick={handleBack}>
              Back
            </Button>
            <Button
              variant="secondary"
              isFullWidth
              backgroundColor="grey50"
              onClick={handleContinue}
              disabled={!nextStepReady()}
            >
              Continue
            </Button>
          </Flex>
        ) : (
          <>
            <Box display="flex" flexDirection="row" justifyContent="flex-start" pb="12px" width="100%" gap="12px">
              <P variant={3}>{modalButtonTitle}</P>
              <Box display="flex" flexShrink={0} marginLeft="auto" gap="12px">
                <P as="div" variant={3}>
                  {ProductDetailsState.totals.formattedPrice}
                </P>
                <P as="div" variant={3} color="grey500" textTransform="uppercase">
                  {ProductDetailsState.formattedKilojoules}
                </P>
              </Box>
            </Box>
            <Box flexGrow={1}>{confirmButton}</Box>
          </>
        )}
      </>
    );

    const modalProps = {
      modalOpen,
      modalFooter,
      modalButtonTitle,
      modalScroll,
      modalScreens: MODAL_SCREENS,
      handleModalClose,
    };

    const productDetailProps = {
      sizePickerEnabled,
      byoChoices,
      showPerksSash,
      showMealCta,
      handleQuantityChange,
      highlightMissingRequiredChoices,
    };

    if (isByoProduct) {
      return <ByoProductContent {...modalProps} {...productDetailProps} />;
    }

    return (
      <Modal
        handleClose={handleModalClose}
        modalTitle={displayProduct.name}
        open={modalOpen}
        buttonTitle={modalButtonTitle}
        buttonSecondaryTitle={ProductDetailsState.formattedKilojoules}
        buttonPrice={ProductDetailsState.totals.formattedPrice}
        footer={modalFooter}
        footerPosition="relative"
        isDividerHidden
        showShadow
        header={<ModalFloatingHeader handleClose={handleModalClose} />}
        backgroundImage="/static/images/background.svg"
      >
        <Flex flexDirection="column" gap="2rem">
          <Box bg="white" ref={modalScroll} height="fit-content">
            <ProductDetailHeader
              product={displayProduct}
              points={ProductDetailsState.formattedPoints}
              showPerksSash={showPerksSash}
            />
            {sizePickerEnabled && product.hasOtherSizes && (
              <Box pt={2}>
                <ProductSizePicker
                  sizes={product.sizes}
                  onChange={newSize => {
                    ProductDetailsState.setSize(newSize);
                  }}
                  value={product.id}
                />
              </Box>
            )}
          </Box>

          {product.initialChoices.map((choice, index) => {
            return (
              <Box
                key={choice.name}
                id={choice.name}
                ref={node => {
                  choiceNameRefs.current[choice.name] = node;
                }}
              >
                <ChoicePicker
                  choice={choice}
                  isChoiceInvalid={
                    highlightMissingRequiredChoices && includes(ProductDetailsState.missingRequiredChoices, choice.name)
                  }
                  index={index}
                />
              </Box>
            );
          })}

          {product.extraChoice ? (
            <Box
              key={product.extraChoice.name}
              id={product.extraChoice.name}
              ref={node => {
                choiceNameRefs.current[product.extraChoice.name] = node;
              }}
            >
              <ChoicePicker
                choice={product.extraChoice}
                isChoiceInvalid={
                  highlightMissingRequiredChoices &&
                  includes(ProductDetailsState.missingRequiredChoices, product.extraChoice.name)
                }
              />
            </Box>
          ) : null}

          {product.removeChoice && !showRemoveIngredientMenu ? (
            <Box px="1.5rem">
              <Button
                variant="secondary"
                isFullWidth
                onClick={() => setShowRemoveIngredientMenu(!showRemoveIngredientMenu)}
              >
                Remove ingredients
              </Button>
            </Box>
          ) : null}

          {showRemoveIngredientMenu && <RemoveIngredientsScreen />}

          {orderItem.showAllergenInfo || orderItem.showNutritionalInfo ? (
            <Flex justifyContent="center">
              <A variant={2} textAlign="center" onClick={() => setShowAllergenAndNutritionalModal(true)}>
                Allergen and Nutritional Information
              </A>
            </Flex>
          ) : null}

          {showAllergenAndNutritionalModal ? (
            <>
              <Box
                bg="rgba(0, 0, 0, 0.3)"
                position="absolute"
                height="100%"
                width="100%"
                zIndex={2}
                top={0}
                onClick={() => {
                  setShowAllergenAndNutritionalModal(false);
                }}
              />

              <Box
                bg="white"
                position="absolute"
                bottom={0}
                width="100%"
                maxHeight="66%"
                zIndex={3}
                backgroundImage="url(/static/images/background.svg)"
                overflowY="auto"
              >
                <Flex px="1.5rem" py="1rem" ref={modalScrollTop} gap="1rem" flexDirection="column">
                  <AllergyInformation />
                  <NutritionalInformation />
                </Flex>
              </Box>
            </>
          ) : null}

          {displayProduct.hasMealUpgrade && showMealCta && (
            <>
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                bg={colors.neonYellow}
                mx="1.5rem"
                py="0.2rem"
                px="0.5rem"
                borderRadius="4px"
              >
                <H3>Make it a meal</H3>
                <img
                  alt="Peri Perks logo"
                  src="/static/images/brand/red-flame.svg"
                  width="30"
                  style={{
                    marginTop: '-1.5rem',
                  }}
                />
              </Flex>
              <P mx="1.5rem" fontWeight="bold">
                Add a regular side and a drink from {product.mealUpgradePrices().formattedPrice}
              </P>
              <MealChoices />
            </>
          )}

          <Flex px="1.5rem" pb="1.5rem" justifyContent="space-between" alignItems="center">
            <P variant={2} fontWeight="bold">
              Quantity
            </P>
            <QuantityPicker
              quantity={quantity}
              increment={() => handleQuantityChange(quantity + 1)}
              decrement={() => handleQuantityChange(quantity - 1)}
              max={9}
              min={1}
              hideWhenDisabled="min"
            />
          </Flex>
          {ProductDetailsState.id && (
            <Flex pt={2} pb={2} justifyContent="center">
              {/* @NOTE: removeFromCart() removes the order item from both
                the ProductDetailsState and the CartStore. */}
              <A
                variant={2}
                textAlign="center"
                onClick={() => {
                  ProductDetailsState.removeFromCart();
                  handleModalClose();
                }}
              >
                Remove from order
              </A>
            </Flex>
          )}
        </Flex>
      </Modal>
    );
  }
);

ProductDetailModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalSubmit: PropTypes.func,
  showMealCta: PropTypes.bool,
};

ProductDetailModal.defaultProps = {
  handleModalSubmit: null,
  showMealCta: true,
};

export { ProductDetailModal };
