import { color, layout, position, space } from 'styled-system';
import styled from 'styled-components';

import { shouldForwardProp } from '../../../../utils/should-forward-prop';

const Placeholder = props => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 129 187" {...props}>
    <path d="m128.44 67.05.018-.124.016-.106a49.003 49.003 0 0 0-3.435-19.736 48.52 48.52 0 0 0-13.46-16.842 80.47 80.47 0 0 1-3.574-3.834c-2.082-2.426-2.915-4.759-2.415-7.132l.011-.07a7.177 7.177 0 0 1 .476-1.528c.016-.115.093-.203.135-.306l.066-.143c1.083-2.721 3.648-7.672 8.716-10.235l.116-.055.094-.085a1.462 1.462 0 0 0 .561-1.245 1.167 1.167 0 0 0-.554-.84l-4.94-3.501a1.79 1.79 0 0 0-.806-.29l-.115-.016a16.076 16.076 0 0 0-10.67 5.075 36.358 36.358 0 0 0-6.69 9.952c-1.167 1.815-3.139 2.953-6.048 3.467-1.084.194-2.283.37-3.754.553l-.07-.01c-.66.002-1.293.009-1.91.027-1.578-.068-7.163.66-7.799.747l-.367.046-.007.044-.251.053a39.886 39.886 0 0 0-5.184 1.319c-1.415.416-17.704 5.653-23.667 28.79a71.447 71.447 0 0 0-2.11 15.803 42.255 42.255 0 0 1-.424 2.529l-.02.132-.012.089c.247 24.685-7.259 73.456-36.957 99.913-.062.054-6.08 5.781-1.648 12.576 1.232 1.885 3.437 3.164 6.558 3.792 4.572.691 9.232.54 13.749-.446 5.521-.728 14.72-2.651 27.013-7.714a108.753 108.753 0 0 0 13.128-6.268l1.97-1.13c1.966-1.102 3.754-2.159 5.001-3.032a84.4 84.4 0 0 0 12.99-9.836l.126-.117c.8-.786 1.764-1.638 2.7-2.485 1.414-1.273 2.747-2.487 3.407-3.238a110.478 110.478 0 0 0 13.914-16.524L108.771 125c6.186-9.286 12.228-24.388 12.279-24.552 4.688-12.438 7.17-23.62 7.39-33.399Z" />
  </StyledSvg>
);

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp,
})`
  width: 100%;
  ${position}
  ${space}
  ${layout}
  ${color}
`;

export { Placeholder };
