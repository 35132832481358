import { isArray } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';
import { CANONICAL_CHOICE_NAMES, colors } from '@nandosaus/constants';
import { BastingChoiceType } from './choice-types/basting';
import { Box, Flex } from '../../grid';
import { CheckboxChoiceType } from './choice-types/checkbox';
import { ChoicePropType } from './prop-types';
import { FilterControl } from './filter-control';
import { H3, P } from '../../typography';
import { RadioChoiceType } from './choice-types/radio';

const CHOICE_TYPE = {
  BASTING: 'basting',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
};

const choiceTypeComponent = {
  [CHOICE_TYPE.BASTING]: BastingChoiceType,
  [CHOICE_TYPE.CHECKBOX]: CheckboxChoiceType,
  [CHOICE_TYPE.RADIO]: RadioChoiceType,
};

const { DRINK, SIDE } = CANONICAL_CHOICE_NAMES;

const ChoicePicker = ({ choice, isChoiceInvalid, index, onClick, selectedOptions, isMealPicker }) => {
  const { ProductDetailsState } = useRootStore();

  const isMealChoice = choice.canonicalName === DRINK || choice.canonicalName === SIDE;

  const ChoiceComponent = choiceTypeComponent[choice.type] || RadioChoiceType;

  const mealPickerOverrides = isMealPicker
    ? {
        selectedOptions: selectedOptions || ProductDetailsState.valuesForMealChoice(choice),
        options: choice.optionsForMealPicker,
        getItemValue: option => option.groupName || option.value,
        getItemLabel: option => option.groupName || option.label,
      }
    : {};
  return (
    <Box>
      <Box px="1.5rem">
        <Flex justifyContent="left" mb="1rem">
          <H3 mt={0} bg={isMealChoice ? colors.neonYellow : colors.green300} py="0.2rem" px="0.5rem" borderRadius="4px">
            {choice.name}
          </H3>
        </Flex>
        {choice.required && (
          <P color={isChoiceInvalid ? 'redPrimary' : 'greyPrimary'} variant={3} mb="1rem">
            Required
          </P>
        )}
        <FilterControl hasDisabledOptions={choice.hasDisabledOptions} />
      </Box>
      <ChoiceComponent
        choice={choice}
        hasError={isChoiceInvalid}
        selectedOptions={ProductDetailsState.optionIdsForChoiceName(choice.name)}
        onChange={value => {
          if (onClick) {
            onClick(value);
          } else {
            ProductDetailsState.updateChoice(choice.name, isArray(value) ? value : [value]);
          }
        }}
        index={index}
        {...mealPickerOverrides}
      />
    </Box>
  );
};

ChoicePicker.propTypes = {
  choice: ChoicePropType.isRequired,
  isChoiceInvalid: PropTypes.bool,
  index: PropTypes.number,
  onClick: PropTypes.func,
  isMealPicker: PropTypes.bool,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
};

ChoicePicker.defaultProps = {
  isChoiceInvalid: false,
  index: undefined,
  onClick: undefined,
  isMealPicker: false,
  selectedOptions: undefined,
};

const ComposedChoicePicker = observer(ChoicePicker);
export { ComposedChoicePicker as ChoicePicker };
