import { BastingChilli } from '@components/basting-chilli';
import { Box, Flex } from '@components/grid';
import { InputListItem } from '@components/input-list-item';
import { useTheme } from '@hooks/useTheme';
import { BASTINGS, BASTING_TYPES_BY_NAME, colors } from '@nandosaus/constants';
import { getBastingKey } from '@utils/get-basting-key/get-basting-key';
import { trim } from 'lodash';
import PropTypes from 'prop-types';
import { OptionPropType, SelectedOptionsPropType } from './prop-types';

const BastingSelector = ({ bastingOptions, selectedOptions, onChange }) => {
  const theme = useTheme();

  const selectedBastings = bastingOptions.filter(({ value }) => selectedOptions.includes(value));
  const selectedBastingKeys = selectedBastings.map(basting => getBastingKey(basting)).filter(Boolean);

  return (
    <Flex>
      <Box width="50%" data-testid="input-list">
        {bastingOptions.map(item => {
          const key = item.value;
          const isSelected = !!selectedOptions.find(select => select === item.value);
          const bastingKey = BASTING_TYPES_BY_NAME[trim(item.label)];
          const basting = BASTINGS[bastingKey];

          return (
            <InputListItem
              key={key}
              borderBottomWidth="0"
              icon={item.icon}
              label={item.label}
              py={{ _: '0.75rem', lg: '0.75rem' }}
              labelProps={{
                color: colors[bastingKey],
                fontFamily: 'brand',
                fontWeight: 'normal',
                textTransform: 'none',
                fontSize: [theme.fontSize[6], theme.fontSize[5], null, null, null],
              }}
              onSelect={() => onChange(item.value)}
              selected={isSelected}
              selectedHelperText={basting?.description}
              type="radio"
            />
          );
        })}
      </Box>
      <Flex flex="1" justifyContent="flex-end" alignItems="center">
        <BastingChilli bastings={selectedBastingKeys} width={{ _: '180px', md: '220px', lg: '240px' }} />
      </Flex>
    </Flex>
  );
};

BastingSelector.propTypes = {
  bastingOptions: PropTypes.arrayOf(OptionPropType).isRequired,
  selectedOptions: SelectedOptionsPropType.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { BastingSelector };
