import { isArray } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';

import { BastingChoiceType } from '../../choice-picker/choice-types/basting';
import { CheckboxChoiceType } from '../../choice-picker/choice-types/checkbox';
import { ChoicePropType } from '../../choice-picker/prop-types';
import { Flex } from '../../../grid';
import { P, Subheading } from '../../../typography';
import { RadioChoiceType } from '../../choice-picker/choice-types/radio';

const choiceTypes = {
  basting: BastingChoiceType,
  checkbox: CheckboxChoiceType,
  radio: RadioChoiceType,
};

const ChoicePicker = ({ choice, isChoiceInvalid, index, onClick, canSelectMultiple }) => {
  const { ProductDetailsState } = useRootStore();

  const ChoiceComponent = choiceTypes[choice.type] || RadioChoiceType;

  const getSelectionLimitText = limit => `Maximum ${limit} ${limit === 1 ? 'selection' : 'selections'}`;

  return (
    <>
      {canSelectMultiple ? (
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
          mb="0.5rem"
          px={{ xs: 2, md: 1 }}
        >
          <Subheading variant={3} fontWeight="bold">
            {choice.name}
          </Subheading>
          {choice.maximumOptionLimit && (
            <P color="grey400" variant={3}>
              {getSelectionLimitText(choice.maximumOptionLimit)}
            </P>
          )}
        </Flex>
      ) : (
        <Flex justifyContent="center" py={2}>
          <Subheading variant={1} textAlign="center">
            {choice.byoStepTitle}
          </Subheading>
        </Flex>
      )}
      <ChoiceComponent
        choice={choice}
        hasError={isChoiceInvalid}
        selectedOptions={ProductDetailsState.optionIdsForChoiceName(choice.name)}
        onChange={value => {
          if (onClick) {
            onClick(value);
          } else {
            ProductDetailsState.updateChoice(choice.name, isArray(value) ? value : [value]);
          }
        }}
        index={index}
        isByo
      />
    </>
  );
};

ChoicePicker.propTypes = {
  choice: ChoicePropType.isRequired,
  isChoiceInvalid: PropTypes.bool,
  index: PropTypes.number,
  onClick: PropTypes.func,
  canSelectMultiple: PropTypes.bool.isRequired,
};

ChoicePicker.defaultProps = {
  isChoiceInvalid: false,
  index: undefined,
  onClick: undefined,
};

const ComposedChoicePicker = observer(ChoicePicker);
export { ComposedChoicePicker as ChoicePicker };
