import { BYO_PRODUCT_TYPES } from '@nandosaus/constants';
import { byoBurgerStep1, byoBurgerStep2, byoBurgerStep3, byoBurgerStep4 } from '@nandosaus/animations';
import { layout } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { LottieAnimator } from '../../../../lottie-animator';
import { useIsMinWidth } from '../../../../../hooks/use-is-min-width';

const Image = styled.img`
  ${layout}
`;

const imagePath = '/static/images/build-a-burger';
const ImageSrc = {
  burger: [`${imagePath}/burger/burger-base-step.svg`],
  pita: [
    `${imagePath}/pita/pita-step-1.svg`,
    `${imagePath}/pita/pita-step-2.svg`,
    `${imagePath}/pita/pita-step-3.svg`,
    `${imagePath}/pita/pita-step-4.svg`,
  ],
  wrap: [
    `${imagePath}/wrap/wrap-step-1.svg`,
    `${imagePath}/wrap/wrap-step-2.svg`,
    `${imagePath}/wrap/wrap-step-3.svg`,
    `${imagePath}/wrap/wrap-step-4.svg`,
  ],
};

const burgerAnimations = [undefined, byoBurgerStep1, byoBurgerStep2, byoBurgerStep3, byoBurgerStep4];

const ImageByProduct = ({ buildYourOwnChoiceStep, byoProductType }) => {
  const isMobile = !useIsMinWidth('lg');
  const isBurger = byoProductType === BYO_PRODUCT_TYPES.BURGER;
  const shouldRenderAnimation = isBurger && buildYourOwnChoiceStep > 0;
  return shouldRenderAnimation ? (
    <LottieAnimator
      animationDataFile={burgerAnimations[buildYourOwnChoiceStep]}
      style={{ width: isMobile ? 375 : 580 }}
      ariaLabel={`${byoProductType} build step ${buildYourOwnChoiceStep + 1}`}
    />
  ) : (
    <Image
      src={ImageSrc[byoProductType][buildYourOwnChoiceStep]}
      {...(isBurger ? { style: { width: isMobile ? 375 : 580 } } : { width: { xs: '60%', lg: '100%' } })}
      alt={`${byoProductType} build step ${buildYourOwnChoiceStep + 1}`}
    />
  );
};

ImageByProduct.propTypes = {
  buildYourOwnChoiceStep: PropTypes.number.isRequired,
  byoProductType: PropTypes.string,
};

ImageByProduct.defaultProps = {
  byoProductType: BYO_PRODUCT_TYPES.BURGER,
};

export { ImageByProduct };
