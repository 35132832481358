import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import { OptionPropType } from '@components/basting-selector/prop-types';
import { Box } from '../../../grid';
import { Image } from '../../../image';
import { Placeholder } from '../placeholder';

const StyledImage = styled(Image)`
  transition: 350ms ease-out;
  height: auto;
  border-radius: 6px;

  button:not(:disabled):hover & {
    transform: scale(1.05);
  }
`;

const Container = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  aspect-ratio: 1 / 1;

  ${({ selected, theme }) => selected && `color: ${theme.colors.success600}; border: 2px solid;`}
`;

export const OptionImage = ({ option, selected, width, height }) => {
  return (
    <Container selected={selected} disabled={option?.disabled}>
      {option.image ? (
        <StyledImage width={width} height={height} src={option.image} loading="lazy" opacity={0.5} />
      ) : (
        <Box display="flex" width="100%" backgroundColor="#B1F5EB" justifyContent="center" alignItems="center">
          <Placeholder />
        </Box>
      )}
    </Container>
  );
};

OptionImage.propTypes = {
  option: OptionPropType.isRequired,
  selected: PropTypes.bool.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

OptionImage.defaultProps = {
  width: '100%',
  height: undefined,
};
