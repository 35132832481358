import { ALCOHOL_DISCLAIMER, colors, MARKET } from '@nandosaus/constants';
import { observer } from 'mobx-react';
import { productPropTypes } from '@nandosaus/prop-types';
import { useRootStore } from '@nandosaus/state-management';
import styled from 'styled-components';
import { ImageFadeOverlay } from '@components/image-fade-overlay';
import PropTypes from 'prop-types';
import { Box, Flex } from '../../grid';
import { DefaultImage } from '../../default';
import { HeadingWithLineHighlights } from '../../heading-with-line-highlights';
import { Image } from '../../image';
import { P } from '../../typography';
import { Tags } from '../../tags';
import { useFeature } from '../../../hooks/use-feature';
import { useMarket } from '../../../hooks/use-market';

const PerksContainer = styled.div`
  position: absolute;
  display: flex;
  direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 1rem;
  left: 1rem;
  background-color: white;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  gap: 0.5rem;
`;

const ProductDetailHeader = observer(({ product, points, showPerksSash }) => {
  const { ProductDetailsState } = useRootStore();
  const market = useMarket();

  const isAustralianMarket = market === MARKET.AUSTRALIA;

  const sizePickerEnabled = useFeature('product-size-picker');
  const showPrice = !sizePickerEnabled || (product.hasOtherSizes !== undefined && !product.hasOtherSizes);

  const { disclaimer, isAlcoholProduct } = ProductDetailsState;
  const showProductDetails = showPrice || isAustralianMarket || product.formattedDietaryPreferencesList;

  return (
    <>
      <Box position="relative">
        {points && showPerksSash && (
          <PerksContainer>
            <Box>
              <img alt="Peri Perks logo" src="/static/images/brand/peri-perks-logo-mini.svg" width="30" />
            </Box>
            <P fontWeight="bold" color={colors.grey700}>
              Earn {points} points
            </P>
          </PerksContainer>
        )}
        <ImageFadeOverlay height="100px">
          {product.image ? (
            <Box position="relative">
              <StyledImage src={product.image} alt={product.name} width={576} height={350} objectFit="cover" />
            </Box>
          ) : (
            <DefaultImage height="350px" />
          )}
        </ImageFadeOverlay>
      </Box>

      <Box px="1.5rem" pb={{ _: 2, lg: 1 }}>
        <Box>
          <HeadingWithLineHighlights
            heading={product.name}
            color="white"
            highlightBackgroundColor="neonRed"
            overrideHeadingHighlighting={['alwaysHighlightFirstLine']}
            textAlign="left"
            justifyContent="left"
            zIndex={2}
            mb={1}
            mt={-1}
            pb={0}
          />
          {showProductDetails && (
            <Flex mb="1rem" justifyContent="left" alignItems="center" gap="1rem">
              {showPrice && product.prices.formattedPrice && <P fontWeight="bold">{product.prices.formattedPrice}</P>}
              {isAustralianMarket && product.formattedKilojoules && (
                <P textAlign="center">{product.formattedKilojoules}</P>
              )}
              {product.formattedDietaryPreferencesList && (
                <Box borderLeft={`1px solid ${colors.grey100}`} pl="1rem">
                  <Tags color={colors.green600} fontWeight="bold" labels={product.formattedDietaryPreferencesList} />
                </Box>
              )}
            </Flex>
          )}
          {product.description && (
            <P whiteSpace="pre-wrap" textAlign="left">
              {product.description}
            </P>
          )}
          {isAustralianMarket && (
            <>
              <Flex flexDirection="column">
                {isAlcoholProduct && (
                  <P variant={3} mt="1rem">
                    {ALCOHOL_DISCLAIMER}
                  </P>
                )}
                <P variant={3} mt="1rem">
                  {disclaimer}
                </P>
              </Flex>
            </>
          )}
        </Box>
      </Box>
    </>
  );
});

const StyledImage = styled(Image)`
  width: 100%;
`;

ProductDetailHeader.propTypes = {
  product: productPropTypes.isRequired,
  showPerksSash: PropTypes.bool,
  points: PropTypes.string,
};

ProductDetailHeader.defaultProps = {
  showPerksSash: false,
  points: undefined,
};

export { ProductDetailHeader };
