import * as RadioGroup from '@radix-ui/react-radio-group';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ProductSizeButton } from '../product-size-button';

const StyledRadioGroupRoot = styled(RadioGroup.Root)`
  display: grid;
  gap: ${({ theme }) => theme.space[1]};
`;

const ProductSizesGroup = ({ sizes, value, onChange }) => {
  return (
    <StyledRadioGroupRoot value={value}>
      {sizes.map(product => {
        return (
          <RadioGroup.Item
            key={product.id}
            value={product.id}
            disabled={!product.isAvailable}
            asChild
            onClick={() => onChange(product.id)}
          >
            <ProductSizeButton product={product} isFreeDelivery={product.isEligibleForDeliveryDiscount} />
          </RadioGroup.Item>
        );
      })}
    </StyledRadioGroupRoot>
  );
};

ProductSizesGroup.propTypes = {
  sizes: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.string.isRequired,
      isAvailable: PropTypes.bool.isRequired,
      formattedKilojoules: PropTypes.string,
      prices: PropTypes.shape({
        formattedPrice: PropTypes.string,
      }).isRequired,
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ProductSizesGroup.defaultProps = {
  value: undefined,
};

const ComposedProductSizesGroup = observer(ProductSizesGroup);
export { ComposedProductSizesGroup as ProductSizesGroup };
