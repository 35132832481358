import { productPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../analytics';
import { Box, Flex } from '../grid';
import { Button } from '../button';
import { Header } from './header';
import { Icon } from '../icons';
import { Image } from '../image';
import { Label, Subheading } from '../typography';

const MealCta = ({ product, chosenOptions, onButtonClick }) => {
  const hasChosenOptions = chosenOptions.length > 0;

  return (
    <Flex justifyContent="center" alignItems="center" position="relative" minHeight={{ xs: '320px', lg: '460px' }}>
      <Image alt="" src="/static/images/decal/make-it-a-meal.svg" isBackground />
      <Flex my={2} flexDirection="column" alignItems="center" width={{ xs: 8 / 12, md: 10 / 12 }} zIndex={1}>
        <Header alignItems="flex-end" mb={1} mealSelected={hasChosenOptions} />
        {hasChosenOptions ? (
          <>
            <Box mb={1}>
              {chosenOptions.map(orderOption => (
                <Flex key={orderOption.option.name} mb="0.5rem">
                  <Icon name="triangle" mr="0.5rem" rotate="90deg" width="12px" height="12px" />
                  <Label>{orderOption.option.name}</Label>
                </Flex>
              ))}
            </Box>
            <Button
              variant="secondary"
              onClick={() => {
                analytics.track(ANALYTICS_EVENTS.PRODUCT_MEAL_CTA_CLICKED, { product: product.name });

                onButtonClick();
              }}
            >
              Edit meal options
            </Button>
          </>
        ) : (
          <>
            <Flex position="relative" mb={1} justifyContent="center">
              <Icon
                name="triangle"
                position="absolute"
                top="-0.5rem"
                left="-0.25rem"
                rotate="165deg"
                fill="pink300"
                size={{ _: '11px', md: '16px' }}
              />
              <Subheading textAlign="center" variant={3} zIndex={1}>
                Add a regular side <br /> and a drink from {product.mealUpgradePrices().formattedPrice}
              </Subheading>
            </Flex>
            <Button
              onClick={() => {
                analytics.track(ANALYTICS_EVENTS.PRODUCT_MEAL_CTA_CLICKED, { product: product.name });

                onButtonClick();
              }}
            >
              Add Side and Drink
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
};

MealCta.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  product: productPropTypes.isRequired,
  chosenOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
};

export { MealCta };
