import PropTypes from 'prop-types';

export const ProductPropType = PropTypes.shape({
  size: PropTypes.string.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  formattedKilojoules: PropTypes.string,
  prices: PropTypes.shape({
    formattedPrice: PropTypes.string,
  }).isRequired,
}).isRequired;

export const SizesPropType = PropTypes.arrayOf(ProductPropType).isRequired;
