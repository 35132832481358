import { BYO_PRODUCT_TYPES } from '@nandosaus/constants';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Flex } from '../../../../grid';
import { Image } from '../../../../image';
import { ImageByProduct } from './images-by-product';

const ByoProductDetailImage = ({ isLastStep, buildYourOwnChoiceStep, byoProductType }) => {
  const imagePath = '/static/images/build-a-burger';

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="relative"
      width="100%"
      maxWidth={576}
      height={{ xs: 250, md: 350 }}
    >
      <Box position="absolute">
        <Image
          src={`${imagePath}/background.png`}
          width="100%"
          height={{ xs: 250, md: 350 }}
          objectFit="cover"
          alt="byo-background"
        />
      </Box>
      <Flex zIndex={1} alignItems="center" justifyContent="center">
        <ImageByProduct buildYourOwnChoiceStep={buildYourOwnChoiceStep} byoProductType={byoProductType} />
      </Flex>
      {byoProductType !== BYO_PRODUCT_TYPES.BURGER && isLastStep && (
        <Box position="absolute" zIndex={1} left={{ xs: '12%', md: '20%', lg: '15%' }}>
          <Image width={{ xs: '80%', lg: '100%' }} src={`${imagePath}/stars.svg`} />
        </Box>
      )}
    </Flex>
  );
};

ByoProductDetailImage.propTypes = {
  isLastStep: PropTypes.bool,
  buildYourOwnChoiceStep: PropTypes.number.isRequired,
  byoProductType: PropTypes.string,
};

ByoProductDetailImage.defaultProps = {
  isLastStep: false,
  byoProductType: BYO_PRODUCT_TYPES.BURGER,
};

export { ByoProductDetailImage };
