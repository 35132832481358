import { filter, isEmpty, toLower } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import React, { useEffect } from 'react';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../analytics';
import { Box, Flex } from '../grid';
import { Feature } from '../feature';
import { NutritionalTable } from './nutritional-table';
import { P } from '../typography';

const NutritionalInformation = () => {
  const { ProductDetailsState } = useRootStore();
  const {
    orderItem: {
      formattedNutrition,
      formattedNutritionWeight,
      formattedNutritionDisclaimer,
      dietaryDisclaimer,
      product,
    },
    isValid,
  } = ProductDetailsState;

  useEffect(() => {
    if (product?.id) analytics.track(ANALYTICS_EVENTS.PRODUCT_NUTRITION_VIEWED, { productId: product?.id });
  }, [product?.id]);

  // Exit early if the product has no nutritional information.
  if (isEmpty(filter(formattedNutrition, ({ serving: { value } }) => value && value !== '0'))) return null;

  return (
    <Box
      borderTopWidth="1px"
      borderTopStyle="solid"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderColor="grey100"
    >
      <P fontWeight="bold">Nutritional Information</P>
      {!isValid && (
        <P variant={2}>Please make selections in order for nutritional and allergen info to be displayed.</P>
      )}
      {isValid && (
        <>
          <Feature
            name="nutritional-data"
            placeholder={<NutritionalTable formattedNutrition={formattedNutrition} placeholder />}
          >
            {!isEmpty(formattedNutritionDisclaimer) && (
              <P variant={3} mt="0.5rem" mb={1}>
                All nutritional information is based on your choice of{' '}
                {formattedNutritionDisclaimer.map(({ name }, index) => (
                  <span key={name}>
                    <strong>{toLower(name)}</strong>
                    {index !== formattedNutritionDisclaimer.length - 1 && <span> and </span>}
                  </span>
                ))}
                . Nutritional info does not reflect any customisations made to menu items.
              </P>
            )}
            <P mb={1} variant={3}>
              Serving size {formattedNutritionWeight.value}
              {formattedNutritionWeight.unit}
            </P>
            <NutritionalTable formattedNutrition={formattedNutrition} />
          </Feature>
          {!!dietaryDisclaimer && (
            <Flex flexDirection="row" mt={2}>
              <Box width="100%">
                <P variant={2} color="grey300">
                  {dietaryDisclaimer}
                </P>
              </Box>
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

const ObservedNutritionalInformation = observer(NutritionalInformation);
export { ObservedNutritionalInformation as NutritionalInformation };
