import { MARKET } from '@nandosaus/constants';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Badge } from '../../../badge';
import { Box } from '../../../grid';
import { P } from '../../../typography';
import { useMarket } from '../../../../hooks/use-market';

const Button = styled(Box).attrs(() => ({
  as: 'button',
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '4px',
  overflow: 'hidden',
  textAlign: 'left',
  height: '100%',
  backgroundColor: 'white',
  px: { _: 2, md: '1.5rem', lg: '1.25rem', xxl: 1 },
  py: { _: 2, md: '1.5rem', lg: '1.25rem', xxl: '0.75rem' },
  width: '100%',
  justifyContent: 'flex-start',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'grey200',
}))`
  transition-duration: 200ms;

  &[data-state='checked'] {
    border-color: ${({ theme }) => theme.colors.success600};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.success600};
  }
`;

const ProductSizeButton = React.forwardRef(({ product, isFreeDelivery, ...radixProps }, forwardedRef) => {
  const market = useMarket();
  const isAustralianMarket = market === MARKET.AUSTRALIA;

  return (
    <Button ref={forwardedRef} {...radixProps} disabled={!product.isAvailable}>
      <Box as="span">
        <P as="span" variant={1} color={product.isAvailable ? 'black' : 'grey300'}>
          {product.size}
        </P>
        {isAustralianMarket ? (
          <P as="span" variant={1} color={product.isAvailable ? 'grey600' : 'grey300'} ml={{ _: 1, md: '0.75rem' }}>
            {product.formattedKilojoules}
          </P>
        ) : null}
      </Box>
      {isFreeDelivery && (
        <Badge
          type="red"
          width="50%"
          px={{ _: '6px', md: '8px' }}
          ml={{ _: 1, md: '0.75rem' }}
          fontSize={{ xs: '10px', lg: '14px' }}
          lineHeight={{ _: '16px', md: '22px' }}
        >
          get free delivery
        </Badge>
      )}
      <Box as="span" ml="auto">
        {product.prices.formattedPrice && (
          <P as="span" variant={1} fontWeight="bold" color={!product.isAvailable ? 'grey300' : 'black'}>
            {product.prices.formattedPrice}
          </P>
        )}
      </Box>
    </Button>
  );
});

ProductSizeButton.propTypes = {
  product: PropTypes.shape({
    size: PropTypes.string.isRequired,
    isAvailable: PropTypes.bool.isRequired,
    formattedKilojoules: PropTypes.string,
    prices: PropTypes.shape({
      formattedPrice: PropTypes.string,
    }).isRequired,
  }).isRequired,
  isFreeDelivery: PropTypes.bool,
};

ProductSizeButton.defaultProps = { isFreeDelivery: false };

const ComposedProductSizeButton = observer(ProductSizeButton);

export { ComposedProductSizeButton as ProductSizeButton };
