import { useRootStore } from '@nandosaus/state-management';
import { Box, Flex } from '@components/grid';
import { P } from '@components/typography';
import { Link } from '@components/link';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../analytics';

export const GeneralAllergenPDFLink = ({ centeredText }) => {
  const { MenuStore } = useRootStore();

  const { allergenPDF, loading, fetchAllergenPDF } = MenuStore;

  useEffect(() => {
    fetchAllergenPDF();
  }, [fetchAllergenPDF]);

  if (!allergenPDF?.url || loading) {
    return null;
  }

  return (
    <Flex flexDirection="row" mt={1}>
      <Box width="100%">
        <P variant={2} textAlign={centeredText ? 'center' : undefined}>
          <Link
            href={allergenPDF.url}
            target="_blank"
            variant={2}
            onClick={() => analytics.track(ANALYTICS_EVENTS.ALLERGEN_DOCUMENT_VIEWED)}
          >
            Nando’s menu dietary and allergen information (PDF)
          </Link>
        </P>
      </Box>
    </Flex>
  );
};

GeneralAllergenPDFLink.propTypes = {
  centeredText: PropTypes.bool,
};

GeneralAllergenPDFLink.defaultProps = {
  centeredText: false,
};
