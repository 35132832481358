import * as Checkbox from '@radix-ui/react-checkbox';
import { AnimatePresence, motion } from 'framer-motion';
import { BASTING_TYPES, BASTING_TYPES_BY_NAME, BASTINGS, colors } from '@nandosaus/constants';
import { kebabCase, map, partition, values } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getBastingKey } from '@utils/get-basting-key/get-basting-key';
import { SelectedOptionsPropType } from '@components/basting-selector/prop-types';
import { Box, Flex } from '../../../grid';
import { ChoicePropType } from '../prop-types';
import { Label, P } from '../../../typography';
import { MixedBastingChilli } from '../../../mixed-basting-chilli';
import { useMarket } from '../../../../hooks/use-market';
import { useTheme } from '../../../../hooks/useTheme';

const getBastingHelperText = ({ maximumOptionLimit, selectedBastingOptions }) => {
  if (selectedBastingOptions.length < maximumOptionLimit) {
    if (maximumOptionLimit === 2) {
      return 'Choose your basting, or mix two together!';
    }

    return `You can select a maximum of ${maximumOptionLimit} ${maximumOptionLimit > 1 ? 'options' : 'option'}.`;
  }

  return `You selected ${selectedBastingOptions.length}/${maximumOptionLimit} ${
    selectedBastingOptions.length > 1 ? 'options' : 'option'
  }. Deselect an option to change your selection.`;
};

const MixedBastingChoiceType = ({ choice, selectedOptions, selectedBastingKeys, onChange }) => {
  const market = useMarket();
  const [[plainishOption], bastingOptions] = partition(choice.optionsForPicker, {
    label: BASTINGS[BASTING_TYPES.PLAIN].name[market],
  });

  const selectedNonPlainishBastings = selectedOptions.filter(value => value !== plainishOption?.value);
  const isPlainishSelected = selectedOptions.includes(plainishOption?.value);

  const getBastingsCheckValue = (isSelected, itemValue) => {
    if (isSelected) return selectedOptions.filter(value => value !== itemValue);

    if (selectedNonPlainishBastings.length < choice.maximumOptionLimit) {
      return selectedNonPlainishBastings.concat(itemValue);
    }
    return selectedOptions;
  };

  const getPlainishBastingCheckValue = () => {
    if (isPlainishSelected) {
      return selectedNonPlainishBastings;
    }
    return [plainishOption?.value];
  };

  return (
    <Box px="1.5rem">
      <P color="greyPrimary" variant={3} textAlign="left" height="2rem">
        {getBastingHelperText({
          maximumOptionLimit: choice.maximumOptionLimit,
          selectedBastingOptions: selectedNonPlainishBastings,
        })}
      </P>
      <Flex>
        <Box width="60%" data-testid="input-list">
          {map(bastingOptions, option => {
            const isSelected = Boolean(selectedOptions.find(value => value === option.value));
            const isDisabled =
              option.disabled || (!isSelected && selectedNonPlainishBastings.length >= choice.maximumOptionLimit);
            const bastingKey = getBastingKey(option);
            const basting = BASTINGS[bastingKey];
            return (
              <BastingInput
                key={kebabCase(option.value)}
                value={option.value}
                checked={isSelected}
                disabled={isDisabled}
                label={{ text: option.label, color: colors[bastingKey] }}
                description={basting?.description}
                onCheckedChange={() => onChange(getBastingsCheckValue(isSelected, option.value))}
              />
            );
          })}

          {plainishOption && (
            <>
              <Flex flexDirection="column" flexGrow={1} alignItems="flex-start">
                <P pt="0.25rem" pr="1rem" variant={4} textAlign="left">
                  Or are you traditional? select:
                </P>
              </Flex>
              <BastingInput
                value={plainishOption.value}
                checked={selectedOptions.includes(plainishOption.value)}
                disabled={plainishOption.disabled}
                label={{ text: plainishOption.label, color: colors[getBastingKey(plainishOption)] }}
                description={BASTINGS[BASTING_TYPES.PLAIN].description}
                onCheckedChange={() => onChange(getPlainishBastingCheckValue())}
              />
            </>
          )}
        </Box>
        <Flex flex="1" width="40%" justifyContent="flex-end" pr="1rem" alignItems="center">
          <MixedBastingChilli
            id={`chilli-${choice.id}`}
            bastings={selectedBastingKeys}
            width={{ _: '180px', md: '220px', lg: '240px' }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

MixedBastingChoiceType.propTypes = {
  choice: ChoicePropType.isRequired,
  selectedOptions: SelectedOptionsPropType.isRequired,
  selectedBastingKeys: PropTypes.arrayOf(PropTypes.oneOf(values(BASTING_TYPES_BY_NAME))),
  onChange: PropTypes.func.isRequired,
};

MixedBastingChoiceType.defaultProps = {
  selectedBastingKeys: [],
};

const RoundRadio = ({ value, disabled, checked, onCheckedChange }) => {
  return (
    <StyledCheckboxRoot
      id={value}
      value={value}
      disabled={disabled}
      checked={checked}
      onCheckedChange={onCheckedChange}
    >
      <StyledCheckboxIndicator>
        <TickBlack width="100%" height="100%" />
      </StyledCheckboxIndicator>
    </StyledCheckboxRoot>
  );
};

RoundRadio.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onCheckedChange: PropTypes.func,
};

RoundRadio.defaultProps = {
  value: '',
  disabled: false,
  checked: false,
  onCheckedChange: null,
};

const BastingInput = ({ value, disabled, checked, label, description, onCheckedChange }) => {
  const { fontSize } = useTheme();

  return (
    <Flex alignItems="center" my="1rem">
      <RoundRadio id={value} value={value} disabled={disabled} checked={checked} onCheckedChange={onCheckedChange} />
      <Flex flexDirection="column">
        <StyledLabel
          as="label"
          disabled={disabled}
          htmlFor={value}
          color={disabled ? 'grey500' : label.color}
          fontFamily="brand"
          fontWeight="normal"
          textTransform="none"
          fontSize={fontSize[6]}
        >
          {label.text}
        </StyledLabel>
        <AnimatePresence initial={false}>
          {description && checked && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                visible: {
                  height: 'auto',
                  opacity: 1,
                  transition: {
                    type: 'tween',
                    mass: 0.5,
                    bounce: 0,
                  },
                },
                hidden: {
                  height: 0,
                  opacity: 0,
                  transition: {
                    type: 'tween',
                    mass: 0.5,
                    bounce: 0,
                  },
                },
              }}
            >
              <P pt="0.25rem" pr="1rem" variant={4}>
                {description}
              </P>
            </motion.div>
          )}
        </AnimatePresence>
      </Flex>
    </Flex>
  );
};

BastingInput.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  onCheckedChange: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
};

const TickBlack = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12a12.001 12.001 0 0 1-12 12ZM9.645 13.635l6.435-6.45a1.5 1.5 0 1 1 2.115 2.13l-7.5 7.5a1.5 1.5 0 0 1-2.115 0l-3.525-3.54A1.5 1.5 0 1 1 7.17 11.16l2.475 2.475Z"
    />
  </svg>
);

const StyledCheckboxRoot = styled(Checkbox.Root)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  border: 1px solid ${({ disabled, theme }) => !disabled && theme.colors.grey300};
  background: ${({ disabled, theme }) => (disabled ? theme.colors.grey100 : theme.colors.white)};
  border: ${({ checked }) => checked && 'none'};
  border-radius: 50%;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: 200ms background-color, border-color;
  margin-right: 0.5rem;
  position: relative;
`;

const StyledCheckboxIndicator = styled(Checkbox.Indicator)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
`;

const StyledLabel = styled(Label)`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: ${({ theme }) => theme.fontSize[7]};
`;

export { MixedBastingChoiceType, RoundRadio };
