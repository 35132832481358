import styled from 'styled-components';

const ImageFadeOverlay = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${({ height }) => height ?? '50px'};
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
  }
`;

export { ImageFadeOverlay };
