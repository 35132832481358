import { BYO_STEP_TITLE_MAPPING } from '@nandosaus/constants';
import { Fragment, useRef } from 'react';
import { includes, isArray, map } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';

import { A, Subheading } from '../../../typography';
import { AllergyInformation } from '../../../allergy-information';
import { Box, Flex } from '../../../grid';
import { ChoiceSummary as ByoChoiceSummary } from '../choice-summary';
import { ByoProductDetailHeader } from '../product-detail-header';
import { ChoicePicker } from '../choice-picker';
import { ChoicePropType } from '../../choice-picker/prop-types';
import { Divider } from '../../../divider';
import { MealCta } from '../../../meal-cta';
import { Modal } from '../../../modal';
import { NutritionalInformation } from '../../../nutritional-information';
import { PerksSash } from '../../perks-sash';

const ByoProductContent = observer(
  ({
    modalOpen,
    modalFooter,
    modalButtonTitle,
    modalScroll,
    setModalScreen,
    modalScreens,
    handleModalClose,
    byoChoices,
    showPerksSash,
    showMealCta,
    highlightMissingRequiredChoices,
  }) => {
    const { ProductDetailsState } = useRootStore();
    const { buildYourOwnChoiceStep, displayProduct, isLastStep, orderItem, product } = ProductDetailsState;
    const choiceNameRefs = useRef({});

    return (
      <Modal
        handleClose={handleModalClose}
        modalTitle={displayProduct.name}
        open={modalOpen}
        showShadow
        buttonTitle={modalButtonTitle}
        buttonSecondaryTitle={ProductDetailsState.formattedKilojoules}
        buttonPrice={ProductDetailsState.totals.formattedPrice}
        footer={modalFooter}
      >
        <Box bg="white" pb={{ _: '14rem', md: '1.25rem' }} ref={modalScroll} height="fit-content">
          <ByoProductDetailHeader product={displayProduct} />
          {isLastStep ? (
            <Flex flexDirection="column" mb={1}>
              <Box flex={1} mt="1.5rem">
                <ByoChoiceSummary product={product} title={`Your ${product.byoType} summary`} />
              </Box>

              {displayProduct.hasMealUpgrade && showMealCta && (
                <MealCta
                  onButtonClick={() => setModalScreen(modalScreens.MEAL)}
                  product={displayProduct}
                  chosenOptions={ProductDetailsState.chosenMealChoiceOptions}
                />
              )}
              {orderItem.showAllergenInfo || orderItem.showNutritionalInfo ? (
                <Box px="2rem">
                  <AllergyInformation />
                  <NutritionalInformation />
                </Box>
              ) : null}
              {showPerksSash && (
                <Box mt={1}>
                  <PerksSash>
                    <strong>Earn {ProductDetailsState.formattedPoints} points</strong> for your {product.longName}{' '}
                    purchase!
                  </PerksSash>
                </Box>
              )}
            </Flex>
          ) : (
            <>
              <Divider height="12px" variant="lightGrey" />
              {map(byoChoices, (choice, index) => {
                if (index === buildYourOwnChoiceStep) {
                  // The screen with multiple ingredients
                  if (isArray(choice)) {
                    const extrasAndSauceChoices = choice;
                    return (
                      <Fragment key={`extra-${index}`}>
                        <Flex flexDirection="column" alignSelf="center" alignItems="center" pt="2.75rem">
                          <Subheading variant={1}>{BYO_STEP_TITLE_MAPPING.extras}</Subheading>
                        </Flex>
                        {map(extrasAndSauceChoices, subChoice => (
                          <Box
                            key={subChoice.name}
                            id={subChoice.name}
                            ref={node => {
                              choiceNameRefs.current[subChoice.name] = node;
                            }}
                            mt="2.75rem"
                          >
                            <ChoicePicker
                              choice={subChoice}
                              isChoiceInvalid={
                                highlightMissingRequiredChoices &&
                                includes(ProductDetailsState.missingRequiredChoices, subChoice.name)
                              }
                              index={index}
                              canSelectMultiple
                            />
                          </Box>
                        ))}
                      </Fragment>
                    );
                  }

                  return (
                    <Box
                      key={choice.name}
                      id={choice.name}
                      ref={node => {
                        choiceNameRefs.current[choice.name] = node;
                      }}
                    >
                      <ChoicePicker
                        choice={choice}
                        isChoiceInvalid={
                          highlightMissingRequiredChoices &&
                          includes(ProductDetailsState.missingRequiredChoices, choice.name)
                        }
                        index={index}
                        canSelectMultiple={false}
                      />
                    </Box>
                  );
                }

                return null;
              })}
            </>
          )}

          {ProductDetailsState.id && (
            <Flex pt={2} pb={2} justifyContent="center">
              {/* @NOTE: removeFromCart() removes the order item from both
          the ProductDetailsState and the CartStore. */}
              <A
                variant={2}
                textAlign="center"
                onClick={() => {
                  ProductDetailsState.removeFromCart();
                  handleModalClose();
                }}
              >
                Remove from order
              </A>
            </Flex>
          )}
        </Box>
      </Modal>
    );
  }
);

ByoProductContent.propTypes = {
  modalOpen: PropTypes.bool,
  modalFooter: PropTypes.node.isRequired,
  modalButtonTitle: PropTypes.string,
  modalScroll: PropTypes.func.isRequired,
  setModalScreen: PropTypes.func.isRequired,
  modalScreens: PropTypes.shape({}).isRequired,
  handleModalClose: PropTypes.func.isRequired,
  sizePickerEnabled: PropTypes.bool,
  byoChoices: PropTypes.arrayOf(PropTypes.oneOfType([ChoicePropType, PropTypes.arrayOf(ChoicePropType)])),
  showPerksSash: PropTypes.bool,
  showMealCta: PropTypes.bool,
  highlightMissingRequiredChoices: PropTypes.bool,
};

ByoProductContent.defaultProps = {
  modalOpen: false,
  modalButtonTitle: '',
  sizePickerEnabled: false,
  byoChoices: [],
  showPerksSash: false,
  showMealCta: false,
  highlightMissingRequiredChoices: false,
};

export { ByoProductContent };
