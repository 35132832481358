import * as RadioGroup from '@radix-ui/react-radio-group';
import { head, isEmpty, map, take } from 'lodash';
import PropTypes from 'prop-types';
import { OptionPropType, SelectedOptionsPropType } from '@components/basting-selector/prop-types';
import { useState } from 'react';
import { Flex } from '@components/grid';
import { CANONICAL_CHOICE_MAPPING, CANONICAL_CHOICE_NAMES } from '@nandosaus/constants';
import { A } from '../../../typography';
import { ByoOptionCard } from '../byo-option-card';
import { ChoicePropType } from '../prop-types';
import { OptionCard, OptionLabel } from '../option-card';
import { OptionGroup } from '../option-group';

const proteinRegex = /protein/g;

const { DRINK } = CANONICAL_CHOICE_NAMES;

export const RadioChoiceType = ({
  choice,
  selectedOptions,
  onChange,
  index,
  options,
  getItemValue,
  getItemLabel,
  isByo,
}) => {
  const radioItems = options || choice.optionsForPicker;

  const isLabelChoice = !isEmpty(choice.id.match(proteinRegex));
  const isImageLabelChoice = CANONICAL_CHOICE_MAPPING[choice.name] === DRINK;

  const [displayExpanded, setDisplayExpanded] = useState(false);

  const isCollapsable = isImageLabelChoice;
  const items = isCollapsable && !displayExpanded ? take(radioItems, 5) : radioItems;

  if ((isLabelChoice || isImageLabelChoice) && !isByo) {
    return (
      <RadioGroup.Root value={head(selectedOptions) || null} orientation="vertical">
        <OptionGroup index={index} isByo={isByo} verticalDisplay={isLabelChoice || isImageLabelChoice}>
          {map(items, item => {
            const value = getItemValue ? getItemValue(item) : item.value;
            const label = getItemLabel ? getItemLabel(item) : item.label;

            return (
              <RadioGroup.Item
                value={value}
                disabled={item.disabled}
                asChild
                onClick={() => onChange(value)}
                key={value}
              >
                <OptionLabel option={item} label={label} showOptionImage={isImageLabelChoice} />
              </RadioGroup.Item>
            );
          })}
          {isCollapsable && (
            <Flex justifyContent="center">
              <A textAlign="center" onClick={() => setDisplayExpanded(!displayExpanded)}>
                {displayExpanded ? 'View less' : 'View all drinks'}
              </A>
            </Flex>
          )}
        </OptionGroup>
      </RadioGroup.Root>
    );
  }

  return (
    <RadioGroup.Root value={head(selectedOptions) || null} orientation="horizontal">
      <OptionGroup index={index} isByo={isByo}>
        {radioItems.map(item => {
          const value = getItemValue ? getItemValue(item) : item.value;
          const label = getItemLabel ? getItemLabel(item) : item.label;

          return (
            <RadioGroup.Item value={value} disabled={item.disabled} asChild onClick={() => onChange(value)} key={value}>
              {isByo ? (
                <ByoOptionCard
                  option={item}
                  label={label}
                  choiceName={choice.name}
                  maxSelection={choice.maximumOptionLimit}
                />
              ) : (
                <OptionCard option={item} label={label} />
              )}
            </RadioGroup.Item>
          );
        })}
      </OptionGroup>
    </RadioGroup.Root>
  );
};

RadioChoiceType.propTypes = {
  choice: ChoicePropType.isRequired,
  selectedOptions: SelectedOptionsPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number,
  options: PropTypes.arrayOf(OptionPropType),
  getItemValue: PropTypes.func,
  getItemLabel: PropTypes.func,
  isByo: PropTypes.bool,
};

RadioChoiceType.defaultProps = {
  index: undefined,
  options: undefined,
  getItemValue: undefined,
  getItemLabel: undefined,
  isByo: false,
};
