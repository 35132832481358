import { forwardRef } from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';

import { H3 } from '@components/typography';
import { colors } from '@nandosaus/constants';
import { Flex } from '../../grid';
import { StylePicker } from '../../style-picker';

const RemoveIngredientsScreen = forwardRef((props, ref) => {
  const { ProductDetailsState } = useRootStore();
  const choice = ProductDetailsState.product.removeChoice;

  return (
    <Flex ref={ref} flexDirection="column" gap="1rem">
      <Flex justifyContent="left" px="1.5rem">
        <H3 mt={0} bg={colors.green300} px="0.5rem" borderRadius="4px">
          Remove Items
        </H3>
      </Flex>
      <StylePicker
        id={choice.id}
        title={choice.name}
        type={choice.type}
        required={choice.required}
        items={choice.optionsForPicker.map(option => ({
          ...option,
          image: undefined,
        }))}
        selectedItems={ProductDetailsState.optionIdsForChoiceName(choice.name)}
        onSelect={selectedValues => {
          const updatedValues = selectedValues.map(item => {
            const id = item.value;
            const value = choice.optionById(id);
            return value;
          });
          ProductDetailsState.updateChoice(choice.name, updatedValues);
        }}
        showClearFilter={choice.hasDisabledOptions}
        showTitle={false}
      />
    </Flex>
  );
});

const ComposedRemoveIngredientsScreen = observer(RemoveIngredientsScreen);
export { ComposedRemoveIngredientsScreen as RemoveIngredientsScreen };
