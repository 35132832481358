import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Flex } from '../../grid';
import { Label, P } from '../../typography';
import { labelSize } from '../../../constants';
import { TableHeader } from '../../table-template/table-header';

const NutritionalTable = ({ formattedNutrition, placeholder }) => {
  if (placeholder) {
    return (
      <Flex data-testid="nutritional-table-placeholder" flexWrap="wrap" mr={-1}>
        {map(formattedNutrition, ({ serving }) => (
          <Flex mt={1} key={`${serving.label}${serving.unit}`} width={1 / 2} pr={2}>
            <Box width="50%">
              <Label color="grey300">{serving.label}</Label>
            </Box>
            <Box width="50%">
              <P variant={2} textAlign="right">
                {serving.value} {serving.unit}
              </P>
            </Box>
          </Flex>
        ))}
      </Flex>
    );
  }

  const headers = [null, 'serve', '100g'];

  return (
    <StyledTable data-testid="nutritional-table">
      <thead>
        <TableHeader headers={headers} isNutritionalTable />
      </thead>
      <tbody>
        {formattedNutrition.map(data => {
          const { serving, per100g } = data;

          return (
            <tr key={`${serving.label}${serving.unit}`}>
              <StyledTableData hasIndentValue={serving.indentValue}>{serving.label}</StyledTableData>
              <td>
                {serving.value} {serving.unit}
              </td>
              <td>
                {per100g.value} {per100g.unit}
              </td>
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey100};
  width: 100%;
  text-align: right;

  td {
    min-width: 100px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey100};
    font-size: ${labelSize.L1MxSx};
  }
`;

const StyledTableData = styled.td`
  padding-left: ${({ hasIndentValue }) => (hasIndentValue ? 20 : 0)}px;
  text-align: left;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey500};
  font-weight: ${({ hasIndentValue }) => (hasIndentValue ? 'normal' : 'bold')};
`;

NutritionalTable.propTypes = {
  placeholder: PropTypes.bool,
  formattedNutrition: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

NutritionalTable.defaultProps = {
  placeholder: false,
};

export { NutritionalTable };
