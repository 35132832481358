import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import { colors } from '@nandosaus/constants';
import { Box, Flex } from '../../grid';
import { H3, P } from '../../typography';
import { ProductSizesGroup } from './product-sizes-group';
import { SizesPropType } from './prop-types';
import { StylePicker } from '../../style-picker';
import { useFeature } from '../../../hooks/use-feature';

const ProductSizePicker = observer(({ sizes, onChange, value, showDisclaimer }) => {
  const isNewProductSizePickerEnabled = useFeature('new-size-picker');

  if (isNewProductSizePickerEnabled) {
    return (
      <Flex flexDirection="column" px="1.5rem">
        <H3 bg={colors.neonYellow} py="0.2rem" px="0.5rem" m="0.5rem" borderRadius="4px" width="fit-content">
          Select size
        </H3>
        <Box position="relative" pt={2} pb={showDisclaimer ? 1 : 2} overflow="hidden">
          <Box position="relative">
            <ProductSizesGroup sizes={sizes} value={value} onChange={onChange} />
          </Box>
          {showDisclaimer ? (
            <P color="grey700" textAlign="center" mt={1} variant={3} zIndex={1} position="relative">
              The average adult daily energy intake is 8,700kJ.
            </P>
          ) : null}
        </Box>
      </Flex>
    );
  }

  return (
    <StylePicker
      title="Size"
      type="radio"
      required
      items={sizes.map(product => ({
        text: product.prices.formattedPrice,
        label: product.size,
        value: product.id,
      }))}
      selectedItems={value === undefined ? [] : [value]}
      onSelect={newOptions => {
        const selectedOption = newOptions[0];

        onChange(selectedOption.value);
      }}
    />
  );
});

ProductSizePicker.propTypes = {
  sizes: SizesPropType,
  showDisclaimer: PropTypes.bool,
};

ProductSizePicker.defaultProps = { showDisclaimer: false };

export { ProductSizePicker };
