import { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// avoid blurry svg caused by `transform: translate3d(0px, 0px, 0px)` being applied to the svg
// @see: https://stackoverflow.com/questions/60258766/lottie-animated-svg-very-pixelated-blurry-in-safari-but-not-chrome-ff
const Wrapper = styled.div`
  height: ${props => (props.isFullHeight ? '100%' : undefined)};
  svg {
    transform: unset !important;
  }
`;

export const LottieAnimator = ({
  animationDataFile,
  loop,
  autoplay,
  animationDelay,
  style,
  ariaLabel,
  isFullHeight,
}) => {
  const lottieRef = useRef();
  const [showAnimation, setShowAnimation] = useState(false);

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  useEffect(() => {
    const animateAfterDelay = async () => {
      await sleep(animationDelay);
      setShowAnimation(true);
    };
    animateAfterDelay();
  }, [animationDelay]);

  if (showAnimation) {
    return (
      <Wrapper isFullHeight={isFullHeight}>
        <Lottie
          data-testid="lottie-react"
          animationData={animationDataFile}
          lottieRef={lottieRef}
          loop={loop}
          autoplay={autoplay}
          style={isFullHeight ? { ...style, height: '100%' } : style}
          aria-label={ariaLabel}
        />
      </Wrapper>
    );
  }

  return null;
};

LottieAnimator.propTypes = {
  animationDataFile: PropTypes.shape({}).isRequired,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  animationDelay: PropTypes.number,
  style: PropTypes.shape({}),
  ariaLabel: PropTypes.string,
  isFullHeight: PropTypes.bool,
};

LottieAnimator.defaultProps = {
  loop: false,
  autoplay: true,
  animationDelay: 0,
  style: {},
  ariaLabel: undefined,
  isFullHeight: false,
};
