import { isEmpty, map, size } from 'lodash';
import { PropTypes } from 'prop-types';
import { useRootStore } from '@nandosaus/state-management';

import { Box, Flex } from '../../../grid';
import { Label, P, Subheading } from '../../../typography';

const ChoiceSummary = ({ title }) => {
  const { ProductDetailsState } = useRootStore();
  const { choices } = ProductDetailsState;

  const productChoicesWithSelectedOptions = [];
  choices.forEach((orderOptions, choiceName) => {
    const selectedOptions = map(orderOptions, orderOption => ({
      ...orderOption.option.toJSON(),
      quantity: orderOption.quantity,
      formattedPrice: orderOption.formattedPrice,
    }));

    productChoicesWithSelectedOptions.push({
      name: choiceName,
      selectedOptions,
    });
  });

  const handleChangeChoiceStep = step => {
    ProductDetailsState.goToStep(step);
  };

  return (
    <Box px={2} borderBottom="solid" borderBottomWidth="1px" borderBottomColor="grey100">
      <Subheading mb={3} textAlign="center">
        {title}
      </Subheading>
      {map(productChoicesWithSelectedOptions, (choice, index) => {
        const isLast = index === size(productChoicesWithSelectedOptions) - 1;
        const isChoiceEmpty = isEmpty(choice.selectedOptions);
        return (
          <Box
            key={choice.name}
            mt={1}
            borderBottom="solid"
            borderBottomColor="grey100"
            borderBottomWidth={isLast ? 0 : '1px'}
          >
            <Flex flexDirection="row" justifyContent="space-between" mb={1}>
              <Label variant={2} color="grey500" fontWeight="400">
                {choice.name}
              </Label>
              <Box as="button" onClick={() => handleChangeChoiceStep(index)}>
                <P variant={3} color="info600" textTransform="none">
                  {isChoiceEmpty ? 'Add' : 'Change'}
                </P>
              </Box>
            </Flex>

            <Box>
              {map(choice.selectedOptions, option => {
                return (
                  <Flex key={option.id} flexDirection="row" justifyContent="space-between" mb={1}>
                    <P variant={3}>
                      {option.name} {option.quantity > 1 && `x${option.quantity}`}{' '}
                    </P>
                    <P variant={3} color="grey500">
                      {option.formattedPrice && `+ ${option.formattedPrice}`}
                    </P>
                  </Flex>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

ChoiceSummary.propTypes = {
  title: PropTypes.string,
};

ChoiceSummary.defaultProps = {
  title: 'Your choice summary',
};
export { ChoiceSummary };
