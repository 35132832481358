import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { bodySize, breakpoints } from '../../../constants';

const TableHeader = ({ headers, isNutritionalTable }) => (
  <tr>
    {headers.map(header => (
      <TableHeading key={header} scope="col">
        {isNutritionalTable && header ? (
          <>
            avg qty
            <br />
            per {header}
          </>
        ) : (
          <>{header}</>
        )}
      </TableHeading>
    ))}
  </tr>
);

const TableHeading = styled.th`
  border-collapse: collapse;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  letter-spacing: 0.6px;
  font-size: ${bodySize.L3M2S1};

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${bodySize.L1MxSx};
  }
`;

TableHeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isNutritionalTable: PropTypes.bool,
};

TableHeader.defaultProps = {
  isNutritionalTable: false,
};

export { TableHeader };
